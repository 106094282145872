import Bugsnag from '@bugsnag/js';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userQuery } from '../../../../context/atoms/User';
import { ApplePayButton, useApplePay } from '../../../../entities/apple-pay';
import { PAYMENT_METHODS } from '../../../../entities/payment-method';
import { usePersonalProfileQuery } from '../../../../entities/personal-profile';
import { analyticsService } from '../../../../services/analytics.service';
import { isAndroid, isIOS16OrLater } from '../../../../shared/lib';
import usePaymentHistoryExperiment from '../../../../shared/lib/hooks/use-payment-history-experiment';
import { formatPrice } from '@/shared/lib/price';
import { Loader } from '../../../shared';
import { UiPrimaryButton } from '../../../shared/ui';
import { UpsellAnalytics } from '../../shared/analytics';
import { useWebToAppAndroidExperiment } from '../../shared/hooks/use-web-to-app-android-experiment';
import { useWebToAppExperiment } from '../../shared/hooks/use-web-to-app-experiment';
import { getAndroidWebToAppUrl, getWebToAppUrl } from '../../shared/lib';
import { UiHeader } from '../../shared/ui';
import { UpsellCascadeAPI } from '../api';
import { UPSELL_CASCADE_PAYWALL_CONFIG } from '../config';
import { type MappedUpsellCascadeOffer, getArrow } from '../lib';
import { useUpsellCascadeOffers } from '../model';
import { ChoiceToast } from './ChoiceToast';
import { UpsellOffers } from './UpsellOffers';
import './assets/upsell-cascade.css';
import { useABTestMegaUpsell } from '@/entities/experiments/lib/hooks/useABTestMegaUpsell';

export function UpsellCascadePage() {
  //TODO: refactor user
  const user = useRecoilValue(userQuery);
  const userPersonalProfile = usePersonalProfileQuery(true);

  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];
  const isArrowAi = arrow === 'ai';
  const filteredArrow = getArrow(arrow);

  const userId = user?.user_id as number;

  const { t } = useTranslation();
  const navigation = useHistory();
  const [parent] = useAutoAnimate();
  const { isLoading, isError, offers, hasVat, currencySign } =
    useUpsellCascadeOffers();
  const [selectedOffer, setSelectedOffer] =
    useState<MappedUpsellCascadeOffer>();
  const isWebToAppScreenShow = useFeatureIsOn('ios_web2app_screen_show');

  const webToAppExperiment = useWebToAppExperiment();
  const androidWebToAppExperiment = useWebToAppAndroidExperiment();

  const { bucket: growthBookUpsellGigaValue } = useABTestMegaUpsell();

  const growthbookShowPaymentHistory = usePaymentHistoryExperiment();

  const pageQuery = useMemo(
    () => qs.parse(navigation.location.search),
    [navigation.location.search]
  );

  const queryArrow = useMemo(() => pageQuery.arrow as string, [pageQuery]);
  const queryAssistant = useMemo(
    () => pageQuery.assistant as string,
    [pageQuery]
  );
  const assistantTestValue = queryAssistant ?? 'default';

  const onboardingArrow = queryArrow ? queryArrow.replace('_upsell', '') : '';
  const redirectUrl = queryArrow
    ? `/onboarding/v2?arrow=${onboardingArrow}`
    : filteredArrow
      ? `/onboarding/v2?arrow=${filteredArrow}`
      : '/onboarding/v2';

  const isChoiceToastInitialVisible = useMemo(
    () => pageQuery.isReportOfferAdded === 'true',
    [pageQuery]
  );

  const FEATURES_LIST = t('upsell-cascade.features-list', {
    returnObjects: true,
  }) as Array<string>;

  const isHasUpsells = !isEmpty(offers);

  const {
    lastPaymentMethodQuery,
    isApplePayAvailableInSystem,
    isLastPaymentMethodApplePay,
    isApplePay,
    isOneClick,
  } = useApplePay();

  const isUpsellAvailable = useMemo(
    () => (isLastPaymentMethodApplePay ? isApplePayAvailableInSystem : true),
    [isLastPaymentMethodApplePay]
  );

  const vatPrice = useMemo(
    () =>
      selectedOffer
        ? `${selectedOffer.discountPrice} + ${currencySign}${formatPrice(
            selectedOffer.vatPrice
          )} VAT`
        : '',
    [selectedOffer]
  );

  useEffect(() => {
    analyticsService.setAmplitudeUserProperties({
      exp_profile_payment_history_v1: growthbookShowPaymentHistory,
    });
  }, [growthbookShowPaymentHistory]);

  useEffect(() => {
    if (assistantTestValue) {
      UpsellAnalytics.pageView({
        place: 'signup_multioffer_upsell',
        paywall_config_id: 'upsell_multiple_offers',
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
      });
    }
  }, [assistantTestValue]);

  useEffect(() => {
    if (offers && isHasUpsells) {
      setSelectedOffer(offers[0]);
    }
  }, [offers, isHasUpsells]);

  useEffect(() => {
    if ((!isLoading && !isHasUpsells) || isError) {
      Bugsnag.notify(new Error('ERROR_UPSELL_CASCADE_EMPTY_OFFERS'));
      UpsellAnalytics.pageViewWithError({
        place: 'signup_multioffer_upsell',
        paywall_config_id: 'upsell_multiple_offers',
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
        remote_work: 'default',
        influencer: 'default',
        assistant: isArrowAi ? 'default' : assistantTestValue,
        business: isArrowAi ? assistantTestValue : 'default',
      });
      goToNextPage();
    }
  }, [isLoading, isHasUpsells, isError]);

  function goToNextPage() {
    if (isIOS16OrLater() && isWebToAppScreenShow) {
      navigation.push(getWebToAppUrl(webToAppExperiment));
    } else if (isAndroid()) {
      navigation.push(getAndroidWebToAppUrl(androidWebToAppExperiment));
    } else {
      navigation.replace(redirectUrl);
    }
  }

  function onOfferSelected(offer: MappedUpsellCascadeOffer) {
    UpsellAnalytics.onChooseOffer({
      offerId: offer.id,
      pricingId: offer.pricingId,
      price: offer.rawDiscountPrice,
      currency: offer.currency,
      paywall_config_id: 'upsell_multiple_offers',
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      is_oneclick: isOneClick,
      remote_work: 'default',
      influencer: 'default',
      assistant: isArrowAi ? 'default' : assistantTestValue,
      business: isArrowAi ? assistantTestValue : 'default',
    });
    setSelectedOffer(offer);
  }

  function onSkip({ position }: { position: 'top' | 'bottom' }) {
    UpsellAnalytics.onSkip({
      position,
      place: 'signup_multioffer_upsell',
      paywall_config_id: 'upsell_multiple_offers',
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      is_oneclick: isOneClick,
    });
    goToNextPage();
  }

  async function purchaseUpsell() {
    if (!selectedOffer) {
      throw new Error('EMPTY_SELECTED_OFFER');
    }

    if (!userId) {
      throw new Error('EMPTY_USER');
    }

    UpsellAnalytics.onTryToPay({
      place: 'signup_multioffer_upsell',
      offerId: selectedOffer.id,
      pricingId: selectedOffer.pricingId,
      price: selectedOffer.rawDiscountPrice,
      currency: selectedOffer.currency,
      paywall_config_id: 'upsell_multiple_offers',
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      is_oneclick: isOneClick,
    });

    return await UpsellCascadeAPI.buyOffer({
      userId,
      pricingId: selectedOffer.pricingId,
      offerId: selectedOffer.id,
    });
  }

  function onPurchase() {
    purchaseUpsell().catch(cause => {
      Bugsnag.notify(new Error('ERROR_UPSELL_CASCADE_PURCHASE', { cause }));
    });

    goToNextPage();
  }

  function onApplePaySuccess() {
    goToNextPage();
  }

  function onApplePayTryToPay() {
    if (!selectedOffer) {
      throw new Error('EMPTY_SELECTED_OFFER');
    }

    UpsellAnalytics.onTryToPay({
      place: 'signup_multioffer_upsell',
      offerId: selectedOffer.id,
      pricingId: selectedOffer.pricingId,
      price: selectedOffer.rawDiscountPrice,
      currency: selectedOffer.currency,
      paywall_config_id: 'upsell_multiple_offers',
      paymentMethod: PAYMENT_METHODS.APPLE_PAY,
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      is_oneclick: isOneClick,
    });
  }

  function onApplePayLoaderClick() {
    if (!selectedOffer) {
      throw new Error('EMPTY_SELECTED_OFFER');
    }

    UpsellAnalytics.onApplePayLoaderClick({
      place: 'signup_multioffer_upsell',
      offerId: selectedOffer.id,
      pricingId: selectedOffer.pricingId,
      price: selectedOffer.rawDiscountPrice,
      currency: selectedOffer.currency,
      paywall_config_id: 'upsell_multiple_offers',
      is_oneclick: isOneClick,
    });
  }

  /**
   * @note
   * Trans - тяжелый компонент для рендера, в profile больше всех занимает.
   * TODO: Нужно перенести setSelectedOffer в какой то globalState и не перерисовывать страницу
   * */

  useEffect(() => {
    if (!lastPaymentMethodQuery.isLoading && !isUpsellAvailable) {
      //TODO: send analytics event?
      navigation.replace(redirectUrl);
    }
  }, [isUpsellAvailable, lastPaymentMethodQuery.isLoading]);

  if (isLoading || lastPaymentMethodQuery.isLoading || !isUpsellAvailable) {
    return <Loader />;
  }

  return (
    <main className="upsell-cascade">
      <UiHeader
        events={{
          onSkip: () => onSkip({ position: 'top' }),
        }}
      />

      <div className="upsell-cascade__container" ref={parent}>
        <ChoiceToast isInitialVisible={isChoiceToastInitialVisible} />

        <div className="upsell-cascade__content">
          <h1 className="upsell-cascade__title">{t('upsell-cascade.title')}</h1>

          <UpsellOffers
            isLoading={isLoading}
            offers={offers}
            className="upsell-cascade__offers"
            selectedOfferId={selectedOffer?.id}
            onOfferSelected={onOfferSelected}
          />

          <ul className="upsell-cascade__features-list">
            {FEATURES_LIST.map((feature, i) => (
              <li
                key={`upsell-cascade-feature-${i}`}
                className="upsell-cascade__feature"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <rect width="20" height="20" fill="#5653FE" rx="10" />
                  <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14.667 6.5 8.25 12.917 5.333 10"
                  />
                </svg>
                <p>{feature}</p>
              </li>
            ))}
          </ul>

          {hasVat && (
            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {t('upsell-cascade.total')}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-amount-gray">{vatPrice}</div>
              </div>
            </div>
          )}

          <div className="upsell-cascade__billing-description">
            <Trans
              i18nKey={
                hasVat
                  ? t('upsell-cascade.billing-description-with-vat')
                  : t('upsell-cascade.billing-description')
              }
              values={{ discountPrice: selectedOffer?.priceWithVat }}
              components={{ b: <b /> }}
            />
          </div>

          {isHasUpsells && (
            <>
              <div className="upsell-cascade__purchase-container">
                {isApplePay && selectedOffer && (
                  <ApplePayButton
                    offerId={selectedOffer.id}
                    pricingId={selectedOffer.pricingId}
                    paywallConfigId={UPSELL_CASCADE_PAYWALL_CONFIG}
                    events={{
                      onTryToPay: onApplePayTryToPay,
                      onLoaderClick: onApplePayLoaderClick,
                      onSuccess: onApplePaySuccess,
                    }}
                  />
                )}

                {!isApplePay && (
                  <UiPrimaryButton
                    className="upsell-cascade__purchase-button"
                    onClick={onPurchase}
                  >
                    {t('upsell-cascade.purchase-button')}
                  </UiPrimaryButton>
                )}
              </div>

              <div className="upsell-cascade__skip-action-container">
                <button
                  type="button"
                  className="upsell-cascade__skip-action-button"
                  onClick={() => onSkip({ position: 'bottom' })}
                >
                  {t('upsell-cascade.skip-action-button')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
}
