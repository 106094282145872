import { Fragment } from 'react';

import { GuideType } from '@/modules/guides/entities/guide';
import { PathCard } from '@/modules/guides-v2/entites/path-card';
import SeparatorIcon from '@/modules/guides-v2/shared/assets/svg/sepator-icon.svg';

import { ArrowGuidesCardsSkeleton } from './ArrowGuidesCardsSkeleton';

interface ArrowGuidesCardsProps {
  guides?: GuideType[];
  onGuideClick: (guide: GuideType) => void;
  getProgress: (guide: GuideType) => number;
}

export const ArrowGuidesCards = ({
  guides,
  onGuideClick,
  getProgress,
}: ArrowGuidesCardsProps) => {
  if (!guides) {
    return <ArrowGuidesCardsSkeleton cardsLength={4} />;
  }

  return (
    <div className="px-4 flex flex-col gap-y-3 mt-6 mb-9">
      {guides.map((guide, index) => (
        <Fragment key={guide.id}>
          <PathCard
            onClick={() => onGuideClick(guide)}
            guide={guide}
            progress={getProgress(guide)}
          />
          {index < guides.length - 1 && (
            <div className="guide-certifcate__separator mt-0">
              <img
                src={SeparatorIcon}
                alt="separator icon"
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
