import { useQuery } from '@tanstack/react-query';

import { UpsellSingleAPI } from '../api';
import { UPSELL_SINGLE_QUERY_KEY } from '../config';

export function useUpsellSingleQuery({
  paywallConfigId,
}: {
  paywallConfigId: string;
}) {
  return useQuery({
    queryKey:  [...UPSELL_SINGLE_QUERY_KEY, paywallConfigId],
    queryFn:   () => UpsellSingleAPI.getOffer(paywallConfigId),
    staleTime: Infinity,
    select:    ({ data }) => {
      const offer = data[0];
      const [initialPrice, discountedPrice, fullPrice] = offer.pricings;

      return {
        ...offer,
        initialPrice,
        discountedPrice,
        fullPrice,
      };
    },
  });
}
