import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { onMasteryPathClick } from '@/modules/guides-v2/entites/path-details/lib/analytics';
import { MoreButton } from '@/shared/ui/MoreButton';

import { GuideType } from '../../../../guides/entities/guide';

import './assets/guides-list-arrow.css';

export const GuidesListArrow = ({
  guides,
  onGuideClick,
  progressList,
}: {
  guides: Array<GuideType>;
  onGuideClick: (guide: GuideType, isPathway: boolean) => void;
  progressList: Record<GuideType['id'], number>;
}) => {
  const { t } = useTranslation();
  const navigation = useHistory();

  function getProgress(guide: GuideType) {
    return progressList[guide.id] * 100;
  }

  function onMoreClick() {
    onMasteryPathClick();

    navigation.push('/path-details');
  }

  return (
    <section className="guides-list-arrow">
      <div className="flex justify-between items-center">
        <h1 className="guides-list-arrow__title">
          {t('guides-v2.other-guides-title')}
        </h1>
        <MoreButton onClick={() => onMoreClick()}>{t('guides-v2.see-all')}</MoreButton>
      </div>

      <div className="guides-other-small-list__list">
        {guides.map(guide => (
          <button
            type="button"
            key={guide.id}
            className="guides-list-arrow__item guides-other-small-list__item"
            onClick={() => onGuideClick(guide, true)}
          >
            <div className="guides-list-arrow__item-image-container">
              <img
                className="guides-list-arrow__item-image"
                width="72"
                height="72"
                src={guide.image}
                role="presentation"
                alt=""
              />
            </div>

            <strong className="guides-list-arrow__item-title">
              {guide.name}
            </strong>
            <div className="guides-list-arrow__item-duration">
              <p>
                {t('guides-v2.lessons')}
                {' '}
                {guide.lessons_count}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                fill="none"
              >
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2"
                  fill="#565B66"
                />
              </svg>
              <p>
                {guide.units_count}
                {' '}
                {t('guides-v2.levels')}
              </p>
            </div>
            <div className="guides-list-arrow__progress">
              <div
                className={classNames('guides-list-arrow__progress-line', {
                  'guides-list-arrow__progress-line__green':
                    getProgress(guide) === 100,
                })}
                style={{ width: `${getProgress(guide)}%` }}
              />
            </div>
          </button>
        ))}
      </div>
    </section>
  );
};
