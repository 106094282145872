import { useTranslation } from 'react-i18next';

interface MegaUpsellHeaderProps {
    discountedPrice: string;
    fullPrice: string;
    discountPercentOffText: string;
}

export const MegaUpsellHeader = ({
  discountedPrice, fullPrice, discountPercentOffText,
}: MegaUpsellHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="upsell__header">
      <div className="upsell__hero-image-container">
        <img
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          src={t('mega-upsell.banner.image') as string}
          role="presentation"
          alt=""
        />
      </div>
      <div className="upsell__hero-image-overlay" />
      <div className="upsell__price-card">
        <div className="upsell__discount">{discountPercentOffText}</div>
        <div className="upsell__price-amount">
          <h1>{discountedPrice}</h1>
          <h2>{fullPrice}</h2>
        </div>
        <div className="upsell__price-description">
          {t('mega-upsell.banner.price-description')}
        </div>
      </div>
    </div>
  );
};
