import i18next from 'i18next';

import { analyticsService } from '@/services/analytics.service';

type PathwayPlace = 'pathway_selection_page' | 'pathway_page';

interface PathwayBaseProps {
  name: string;
}

function onMasteryPathClick() {
  analyticsService.sendEvent('webapp_pathway_click', {
    place:    'pathway_selection_page',
    action:   'open_mastery_path',
    language: i18next.language,
  });
}

function onPathwayMenuClick() {
  analyticsService.sendEvent('webapp_pathway_click', {
    place:    'pathway_page',
    action:   'open_mastery_path',
    language: i18next.language,
  });
}

function onPathwayDetailsView({ name }: PathwayBaseProps) {
  analyticsService.sendEvent('webapp_pathway_details_view', {
    name,
    language: i18next.language,
  });
}

function onPathwayDetailsActionClick({ name }: PathwayBaseProps) {
  analyticsService.sendEvent('webapp_pathway_details_view', {
    name,
    action:   'resume',
    language: i18next.language,
  });
}

export {
  onMasteryPathClick,
  onPathwayMenuClick,
  onPathwayDetailsView,
  onPathwayDetailsActionClick,
  type PathwayPlace,
  type PathwayBaseProps,
};
