import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { usePersonalProfileQuery } from '@/entities/personal-profile';
import { UiNavigation } from '@/modules/ai-bots/shared/ui';
import { GuideType } from '@/modules/guides/entities/guide';
import {
  onPathwayDetailsActionClick,
  onPathwayDetailsView,
} from '@/modules/guides-v2/entites/path-details/lib/analytics';
import { PathDetailsWidget } from '@/modules/guides-v2/widgets/path-details-widget';
import { PersonalizeCertificateBottomSheet } from '@/modules/guides-v2/widgets/personalizeCertificate';
import { ProfileCertificates } from '@/modules/Profile/features/certificates-list/lib';

import Banner from './assets/png/banner.png';

export const PathDetails = () => {
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const [guideId, setGuideId] = useState<GuideType['id']>();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userArrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0] || 'ai';

  const navigation = useHistory();

  const onBottomSheetDismiss = () => {
    setIsBottomSheetVisible(false);
  };

  const onCertificateBottomSheetOpen = (newGuideId: GuideType['id']) => {
    setGuideId(newGuideId);
    setIsBottomSheetVisible(true);
  };

  const onGoBack = () => {
    navigation.replace('/');
  };

  const onActionClick = () => {
    onPathwayDetailsActionClick({ name: userArrow });
  };

  useEffect(() => {
    onPathwayDetailsView({ name: userArrow });
  }, [userArrow]);

  return (
    <main className="ui-base-layout min-vh-full">
      <UiNavigation
        className="border-0"
        title=""
        onBackButtonClicked={onGoBack}
      />
      <img
        src={Banner}
        alt="banner"
        className="w-full"
      />
      <PathDetailsWidget
        onCertificateBottomSheetOpen={onCertificateBottomSheetOpen}
        onActionClick={() => onActionClick()}
      />
      {guideId && (
        <PersonalizeCertificateBottomSheet
          onView={() => ProfileCertificates.onBottomSheetView()}
          onClose={() => ProfileCertificates.onPersonalizeSheetClose()}
          onSubmit={() => ProfileCertificates.onCertificateSubmit()}
          guideId={guideId}
          isVisible={isBottomSheetVisible}
          onDismiss={onBottomSheetDismiss}
        />
      )}
    </main>
  );
};
