import { useQuery } from '@tanstack/react-query';

import { GuideCertificateAPI } from '../../../../../entities/certificates';

export function useCertificatesListQuery({ arrowName }: { arrowName: string }) {
  return useQuery({
    queryKey: ['guide-certificates-list', arrowName],
    queryFn: () =>
      GuideCertificateAPI.getCertificatesList(arrowName).then(
        ({ data }) => data
      ),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
