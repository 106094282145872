import { useFeatureValue } from '@growthbook/growthbook-react';

import { BaseExperimentBuckets } from '@/entities/experiments/model/types';

export const AB_TEST_NAME = 'mega_upsell';

/**
 * Хук для получения информации о текущем состоянии эксперимента "Mega Upsell"
 */
export function useABTestMegaUpsell() {
  const bucket = useFeatureValue<BaseExperimentBuckets>(
    AB_TEST_NAME,
    BaseExperimentBuckets.OFF,
  ) as BaseExperimentBuckets;

  return {
    bucket,
    isFeatureEnabled: bucket === BaseExperimentBuckets.TEST,
  };
}
