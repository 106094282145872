import { SVGProps } from 'react';

interface FlagIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const FlagIcon = ({ size = 22, className, ...props }: FlagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    className={className}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M13.3091 3.83174C14.1932 3.9271 15.1131 3.75094 16.1533 2.93743C16.4043 2.74111 16.7453 2.70514 17.0318 2.84477C17.3183 2.98441 17.5 3.27518 17.5 3.59386V13.0722C17.5 13.3286 17.382 13.5707 17.18 13.7286C15.7961 14.8109 14.4432 15.1087 13.1304 14.9671C11.9003 14.8344 10.7374 14.3146 9.71607 13.8581C9.69734 13.8497 9.67865 13.8413 9.66001 13.833C8.57351 13.3475 7.63526 12.9362 6.69089 12.8343C5.89851 12.7488 5.0774 12.8815 4.16667 13.4956V17.4997C4.16667 17.9599 3.79357 18.333 3.33333 18.333C2.8731 18.333 2.5 17.9599 2.5 17.4997V3.59386C2.5 3.33748 2.61801 3.09537 2.81997 2.93743C4.20391 1.85511 5.55676 1.55731 6.86963 1.69892C8.09967 1.8316 9.26259 2.35144 10.2839 2.80798C10.3027 2.81635 10.3213 2.8247 10.34 2.83303C11.4265 3.31855 12.3647 3.72987 13.3091 3.83174ZM4.16667 11.5939C5.08396 11.1857 5.98585 11.0819 6.86963 11.1773C8.09967 11.3099 9.26259 11.8298 10.2839 12.2863C10.3027 12.2947 10.3213 12.303 10.34 12.3114C11.4265 12.7969 12.3647 13.2082 13.3091 13.3101C14.1015 13.3955 14.9226 13.2629 15.8333 12.6488V5.07215C14.916 5.48033 14.0141 5.58412 13.1304 5.48879C11.9003 5.35611 10.7374 4.83628 9.71607 4.37974C9.69734 4.37136 9.67865 4.36301 9.66001 4.35468C8.57351 3.86916 7.63526 3.45784 6.69089 3.35598C5.89851 3.27051 5.0774 3.40315 4.16667 4.01726V11.5939Z"
    />
  </svg>
);

export default FlagIcon;
