import { useTranslation } from 'react-i18next';

import FlagIcon from '@/modules/guides/widgets/guide-lesson-widget/ui/assets/FlagIcon';

import { UiPrimaryButton } from '../../../../shared/ui';

import './guide-feedback.css';

export const GuideSuccessFeedback = ({
  feedback,
  onContinue,
  onReport,
}: {
  feedback?: string;
  onContinue: () => void;
  onReport?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="guide-feedback guide-success-feedback">
      <div className="guide-feedback__header justify-between">
        <div className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
          >
            <rect
              width="19"
              height="19"
              x=".5"
              y=".5"
              fill="#19AA32"
              rx="9.5"
            />
            <rect
              width="19"
              height="19"
              x=".5"
              y=".5"
              stroke="#19AA32"
              rx="9.5"
            />
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14.666 6.5 8.25 12.917 5.333 10"
            />
          </svg>

          <h4 className="guide-feedback__title">
            {t('guides.lesson-chat.feedback.success.title')}
          </h4>
        </div>
        {onReport && (
          <FlagIcon
            onClick={onReport}
            className="text-[#19aa32] cursor-pointer"
          />
        )}
      </div>

      {feedback && <p className="guide-feedback__feedback">{feedback}</p>}

      <UiPrimaryButton
        className="guide-feedback__button guide-success-feedback__button"
        onClick={onContinue}
      >
        {t('guides.continue-button')}
      </UiPrimaryButton>
    </div>
  );
};
