import { useFeatureValue } from '@growthbook/growthbook-react';

import { BaseExperimentBuckets } from '@/entities/experiments/model/types';

export const AB_TEST_NAME = 'exp_upsell_timer';

/**
 * Хук для получения информации о текущем состоянии эксперимента "Upsell Timer"
 */
export function useABTestUpsellTimer() {
  const bucket = useFeatureValue<BaseExperimentBuckets>(
    AB_TEST_NAME,
    BaseExperimentBuckets.OFF,
  ) as BaseExperimentBuckets;

  return {
    bucket,
    isFeatureEnabled: bucket === BaseExperimentBuckets.TEST,
  };
}
