import { useMemo } from 'react';

import { UpsellPricing } from '@/modules/upsell/upsell-single/api';
import { formatPrice } from '@/shared/lib/price';

interface FormattedPrice {
  fullPrice: string;
  absoluteDiscountPrice: string;
  discountedPrice: string;
  vatPrice: string;
  currencyCode: string;
}

export const useFormattedPrices = (
  pricing: UpsellPricing | null,
  currencySign: string,
): FormattedPrice => useMemo(() => {
  if (!pricing || !currencySign) {
    return {
      fullPrice:             '',
      absoluteDiscountPrice: '',
      discountedPrice:       '',
      vatPrice:              '',
      currencyCode:          '',
    };
  }

  const formatPriceWithCurrency = (amount: number) => `${currencySign}${formatPrice(amount)}`;

  return {
    fullPrice:             formatPriceWithCurrency(pricing.original_pricing.full_price),
    absoluteDiscountPrice: formatPriceWithCurrency(pricing.discount_price),
    discountedPrice:       formatPriceWithCurrency(pricing.original_pricing.discount_price),
    vatPrice:              `${formatPriceWithCurrency(pricing.original_pricing.discount_price)} + ${
      formatPriceWithCurrency(pricing.discount_price_vat_amount)
    } VAT`,
    currencyCode: pricing.currency || '',
  };
}, [pricing, currencySign]);
