import { Trans } from 'react-i18next';

import { GigaUpsell } from '@/modules/upsell/upsell-single/entities/giga-upsell-content/types';
import { getUpsellImageUrl } from '@/shared/lib';

import FileEditIcon from './assets/file-edit-icon.svg';
import SparkleIcon from './assets/sparkle-icon.svg';
import TimeIcon from './assets/time-icon.svg';

interface UpsellBenefitsType {
  data: GigaUpsell['pages'];
}

const iconsList = [SparkleIcon, TimeIcon, FileEditIcon];

export const UpsellBenefitsBlock = ({ data }: UpsellBenefitsType) => (
  <div className="upsell-giga__benefits">
    <div className="upsell-giga__benefits-image">
      <img
        width="312"
        height="252"
        src={getUpsellImageUrl(data.benefits.image)}
        role="presentation"
        alt=""
      />
    </div>

    {data.benefits.benefitsList && data.benefits.benefitsList.length > 0 && (
      <ul className="upsell-giga__benefits-list">
        {data.benefits.benefitsList.map((benefitType, index) => (
          <li
            className="upsell-giga__benefits-item"
            key={benefitType.id}
          >
            <img
              src={iconsList[index]}
              alt=""
              width="24"
              height="24"
            />
            <span>
              <Trans
                i18nKey={benefitType.title}
                components={{ b: <b /> }}
              />
            </span>
          </li>
        ))}
      </ul>
    )}

    <div className="upsell-giga__gift">
      <img
        width="64"
        height="64"
        src={getUpsellImageUrl(data.gifts.image)}
        role="presentation"
        alt=""
      />

      <div className="upsell-giga__gift-content">
        <h5>{data.gifts.title}</h5>
        <h3>{data.gifts.text}</h3>
      </div>
    </div>
  </div>
);
