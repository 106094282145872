import { AxiosResponse } from 'axios';

import { api } from '../../../../services/http.service';

export interface UpsellPricing {
  id: string;
  full_price: number;
  discount_price: number;
  discount: number;
  position: number;
  solid_product_id: string;
  vat: number;
  discount_price_vat_amount: number;
  original_pricing: {
    discount_price: number;
    display_price: number;
    full_price: number;
    original_display_price: number;
  };
  currency: string;
}

export interface UpsellOffer {
  id: string;
  currency: string;
  period: number;
  pricings: Array<UpsellPricing>;
}

export const UpsellSingleAPI = {
  getOffer(
    paywallConfigId: string,
  ): Promise<AxiosResponse<Array<UpsellOffer>>> {
    return api.get('/offers-v2/offers/?pricing_repr=daily', {
      headers: {
        'Paywall-Config-Id': paywallConfigId,
      },
    });
  },
  buyOffer({
    userId,
    pricingId,
    offerId,
    paywallConfigId,
  }: {
    userId: number;
    pricingId: string;
    offerId: string;
    paywallConfigId: string;
  }): Promise<AxiosResponse<void>> {
    return api.post(
      '/payments/solid/orders/one-click',
      {
        user_id:    userId,
        offer_id:   offerId,
        pricing_id: pricingId,
      },
      {
        headers: {
          'Paywall-Config-Id': paywallConfigId,
        },
      },
    );
  },
};
