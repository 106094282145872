import {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  getRemainingTime,
  isExpired,
  setTimerInterval,
} from '@/shared/lib/timer';

interface IInitialData {
  m: string;
  s: string;
}

export const useTimer = (timerName?: string, initialData: IInitialData = {
  m: '00',
  s: '00',
}) => {
  const timerIntervalRef = useRef<NodeJS.Timeout>();
  const [timerDuration, setTimerDuration] = useState({ m: initialData?.m, s: initialData?.s });

  const destroyTimer = () => {
    clearInterval(timerIntervalRef.current);
  };

  useEffect(() => {
    if (!timerName || isExpired(timerName)) {
      setTimerDuration({ m: '00', s: '00' });

      return () => {
        clearInterval(timerIntervalRef.current);
      };
    }

    timerIntervalRef.current = setTimerInterval(
      timerName,
      () => {
        setTimerDuration({ m: '00', s: '00' });
      },
      (expiredTime) => {
        setTimerDuration(getRemainingTime(expiredTime as Date));
      },
    );

    return () => {
      clearInterval(timerIntervalRef.current);
    };
  }, [timerName]);

  return {
    timerIntervalRef,
    timerDuration,
    destroyTimer,
  };
};
