import { useMemo, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SeparatorIcon from '@/modules/guides-v2/shared/assets/svg/sepator-icon.svg';

import { GuideCertificateType } from '../../../../../entities/certificates';
import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { GuideDetailsType } from '../../../../guides/entities/guide';
import { GuideProgressByIdType } from '../../../../guides/entities/guide-progress';
import { UiPrimaryButton } from '../../../../shared/ui';
import { useCreateCertificate } from '../../../entites/guide-certificate/model';
import { GuidesV2Analytics } from '../../../shared/analytics';
import { PersonalizeCertificateBottomSheet } from '../../../widgets/personalizeCertificate';
import './assets/guide-certificate.css';

export const GuideCertificateCard = ({
  guideCertificate,
  progress,
  guide,
}: {
  progress: GuideProgressByIdType;
  guideCertificate: GuideCertificateType;
  guide: GuideDetailsType;
}) => {
  const { t } = useTranslation();
  const [isBottomSheetVisible, setIsBottomSheetVisible] =    useState<boolean>(false);
  const userPersonalProfile = usePersonalProfileQuery(true);
  const userFullName =    userPersonalProfile?.data?.personalization_criteria?.full_name?.[0];

  const guideProgress = useMemo(() => progress.progress * 100, [progress]);
  const isGuideCompleted = useMemo(
    () => guideProgress === 100,
    [guideProgress],
  );

  const createCertificate = useCreateCertificate();

  const onViewCertificateClick = () => {
    GuidesV2Analytics.onViewCertificateClick();

    if (guideCertificate.pdf_url) {
      return window.open(guideCertificate.pdf_url, '_blank');
    }

    if (userFullName) {
      return createCertificate.mutate(
        { id: guide.id },
        {
          onSuccess: (certificateResponse) => {
            window.open(certificateResponse.pdf_url, '_blank');
          },
        },
      );
    }

    return setIsBottomSheetVisible(true);
  };

  const onBottomSheetDismiss = () => {
    setIsBottomSheetVisible(false);
  };

  return (
    <>
      <div className="guide-certifcate__separator">
        <img
          src={SeparatorIcon}
          alt="separator icon"
        />
      </div>
      <div className="px-4 mt-12">
        <div className="guide-certificate__container px-4 pt-4 pb-[18px]">
          <div className="guide-certificate__container__card">
            <div className="guide-certificate__container__card__inner pt-6 pb-6">
              {isGuideCompleted ? (
                <img
                  src={guideCertificate.success_image}
                  alt=""
                  className=" mx-auto mt-2"
                  width={80}
                  height={80}
                />
              ) : (
                <img
                  src={guideCertificate.blank_image}
                  alt=""
                  className=" mx-auto"
                  width={80}
                  height={80}
                />
              )}
              {isGuideCompleted && (
                <h3 className="text-lg font-bold text-center mt-4 mb-[9px]">
                  {t('guides-v2.certificate.label-complete')}
                </h3>
              )}
              {!isGuideCompleted && (
                <>
                  <h3 className="text-lg font-bold text-center mt-4">
                    {t('guides-v2.certificate.label-incomplete')}
                  </h3>
                  <div className="mt-4 w-full">
                    <div className="bg-fill-primary max-w-[220px] h-[10px] rounded-lg mx-auto" />
                    <div className="bg-fill-primary max-w-[134px] h-[10px] mt-[8px] rounded-lg mx-auto" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="guide-certificate__container__info pt-4">
            <h3 className="text-lg font-bold">
              {isGuideCompleted
                ? t('guides-v2.certificate.complete.title')
                : t('guides-v2.certificate.incomplete.title')}
            </h3>
            <p className="text-md mt-1">
              {isGuideCompleted
                ? t('guides-v2.certificate.complete.description')
                : t('guides-v2.certificate.incomplete.description')}
            </p>
            <div className="flex items-center mt-4">
              <div className="guide-certificate__container__info__progress">
                <div
                  className={classNames(
                    'guide-certificate__container__info__progress__line',
                    {
                      'guide-certificate__container__info__progress__line--green':
                        isGuideCompleted,
                    },
                  )}
                  style={{ width: `${guideProgress}%` }}
                />
              </div>
              <p className="ml-2">
                {guideProgress}
                %
              </p>
            </div>
            {isGuideCompleted && (
              <UiPrimaryButton
                className="mt-4"
                onClick={onViewCertificateClick}
              >
                {t('guides-v2.certificate.view-certificate')}
              </UiPrimaryButton>
            )}
          </div>
        </div>
      </div>
      <PersonalizeCertificateBottomSheet
        onView={() => GuidesV2Analytics.onCertificateView()}
        onSubmit={() => GuidesV2Analytics.onPersonalizeSheetSubmit()}
        onClose={() => GuidesV2Analytics.onPersonalizeSheetClose()}
        guideId={guide.id}
        isVisible={isBottomSheetVisible}
        onDismiss={onBottomSheetDismiss}
      />
    </>
  );
};
