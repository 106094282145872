import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

/**
 * @description
 * upsell_notion_report is default single upsell,
 * sometimes offer_single doesn't come and we use default
 * */

type SingleOfferPageView = {
  offerId: string;
  pricingId: string;
  price: number;
  currency: string;
  place: 'signup_singleoffer_upsell' | 'signup_gigaupsell';
  paymentMethod: string;
  exp_upsell_megaupsell: string;
  is_oneclick: boolean;
  paywall_config_id: string;
  exp_upsell_upsell_cascade_redesign?: boolean;
  headingExpValue?: string | null;
  upsellName?: string;
  complianceTest?: string;
};

type MultipleOfferPageView = {
  place: 'signup_multioffer_upsell';
  paymentMethod: string;
  paywall_config_id: string;
  exp_upsell_megaupsell: string;
  exp_upsell_upsell_cascade_redesign?: boolean;
  is_oneclick: boolean;
  headingExpValue?: string | null;
  upsellName?: string;
};

export const UpsellAnalytics = {
  legacyPageView({
    price,
    currency,
    isDiscountUpsell,
    isOneClick,
    headingExpValue,
    upsellName,
    complianceTest,
  }: {
    price: number;
    currency: string;
    isDiscountUpsell: boolean;
    isOneClick: boolean;
    headingExpValue?: string | null;
    upsellName?: string;
    complianceTest?: string;
  }) {
    analyticsService.sendEvent('pr_funnel_upsell_view', {
      type:                          'gptbible_with_value',
      chase:                         isDiscountUpsell ? 'yes' : 'no',
      pricing:                       'one-time',
      price,
      currency,
      is_oneclick:                   isOneClick,
      exp_headings_value_v2:         headingExpValue,
      name:                          upsellName,
      exp_upsell_compliant_first_v1: complianceTest,
    });
  },
  pageView(props: MultipleOfferPageView | SingleOfferPageView) {
    if (props.place === 'signup_multioffer_upsell') {
      analyticsService.sendEvent('webapp_upsell_view', {
        place:                         props.place,
        feature:                       'signup',
        paywall_config_id:             props.paywall_config_id,
        language:                      i18next.language,
        payment_method:                props.paymentMethod,
        exp_upsell_second_prosperi_v1: props.exp_upsell_megaupsell,
        exp_upsell_upsell_cascade_redesign:
          props.exp_upsell_upsell_cascade_redesign,
        is_oneclick:           props.is_oneclick,
        exp_headings_value_v2: props.headingExpValue,
        name:                  props.upsellName,
      });
    } else {
      analyticsService.sendEvent('webapp_upsell_view', {
        place:                         props.place,
        offer_id:                      props.offerId,
        pricing_id:                    props.pricingId,
        price:                         props.price,
        currency:                      props.currency,
        feature:                       'signup',
        paywall_config_id:             props.paywall_config_id,
        language:                      i18next.language,
        payment_method:                props.paymentMethod,
        exp_upsell_second_prosperi_v1: props.exp_upsell_megaupsell,
        exp_upsell_upsell_cascade_redesign:
          props.exp_upsell_upsell_cascade_redesign,
        is_oneclick:                   props.is_oneclick,
        exp_headings_value_v2:         props.headingExpValue,
        name:                          props.upsellName,
        exp_upsell_compliant_first_v1: props.complianceTest,
      });
    }
  },
  /**
   * @description
   * Send event, when skip upsell-cascade page
   * */
  pageViewWithError({
    place,
    paymentMethod,
    paywall_config_id,
    exp_upsell_megaupsell,
    exp_upsell_upsell_cascade_redesign,
    is_oneclick,
    remote_work,
    influencer,
    assistant,
    business,
  }: {
    place:
      | 'signup_multioffer_upsell'
      | 'signup_singleoffer_upsell'
      | 'signup_gigaupsell';
    paywall_config_id: string;
    paymentMethod: string;
    exp_upsell_megaupsell: string;
    exp_upsell_upsell_cascade_redesign?: boolean;
    is_oneclick: boolean;
    remote_work: string;
    influencer: string;
    assistant: string;
    business: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_view', {
      place,
      paywall_config_id,
      feature:                                   'signup',
      action:                                    'error',
      language:                                  i18next.language,
      payment_method:                            paymentMethod,
      exp_upsell_second_prosperi_v1:             exp_upsell_megaupsell,
      exp_upsell_upsell_cascade_redesign,
      is_oneclick,
      exp_upsell_remote_v1:                      remote_work,
      exp_upsell_influencer_v1:                  influencer,
      exp_ai_assistant_upsell_smoke_v2:          assistant,
      exp_business_ai_assistant_upsell_smoke_v1: business,
    });
  },
  onChooseOffer({
    offerId,
    pricingId,
    price,
    currency,
    paymentMethod,
    paywall_config_id,
    exp_upsell_megaupsell,
    exp_upsell_upsell_cascade_redesign,
    is_oneclick,
    remote_work,
    influencer,
    assistant,
    business,
  }: {
    offerId: string;
    pricingId: string;
    price: number;
    currency: string;
    paywall_config_id: string;
    paymentMethod: string;
    exp_upsell_megaupsell: string;
    exp_upsell_upsell_cascade_redesign?: boolean;
    is_oneclick: boolean;
    remote_work: string;
    influencer: string;
    assistant: string;
    business: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place:                                     'signup_multioffer_upsell',
      feature:                                   'signup',
      action:                                    'select_offer',
      paywall_config_id,
      offer_id:                                  offerId,
      pricing_id:                                pricingId,
      price,
      currency,
      language:                                  i18next.language,
      payment_method:                            paymentMethod,
      exp_upsell_second_prosperi_v1:             exp_upsell_megaupsell,
      exp_upsell_upsell_cascade_redesign,
      is_oneclick,
      exp_upsell_remote_v1:                      remote_work,
      exp_upsell_influencer_v1:                  influencer,
      exp_ai_assistant_upsell_smoke_v2:          assistant,
      exp_business_ai_assistant_upsell_smoke_v1: business,
    });
  },
  onTryToPay({
    place,
    offerId,
    pricingId,
    price,
    currency,
    paymentMethod,
    paywall_config_id,
    exp_upsell_megaupsell,
    exp_upsell_upsell_cascade_redesign,
    is_oneclick,
    headingExpValue,
    upsellName,
    complianceTest,
  }: {
    place:
      | 'signup_multioffer_upsell'
      | 'signup_singleoffer_upsell'
      | 'signup_gigaupsell';
    offerId: string;
    pricingId: string;
    price: number;
    currency: string;
    paymentMethod: string;
    paywall_config_id: string;
    exp_upsell_megaupsell: string;
    exp_upsell_upsell_cascade_redesign?: boolean;
    is_oneclick: boolean;
    headingExpValue?: string | null;
    upsellName?: string;
    complianceTest?: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place,
      feature:                       'signup',
      action:                        'try2pay',
      paywall_config_id,
      offer_id:                      offerId,
      pricing_id:                    pricingId,
      price,
      currency,
      language:                      i18next.language,
      payment_method:                paymentMethod,
      exp_upsell_second_prosperi_v1: exp_upsell_megaupsell,
      exp_upsell_upsell_cascade_redesign,
      is_oneclick,
      exp_headings_value_v2:         headingExpValue,
      name:                          upsellName,
      exp_upsell_compliant_first_v1: complianceTest,
    });
  },
  onApplePayLoaderClick({
    place,
    offerId,
    pricingId,
    price,
    currency,
    paywall_config_id,
    is_oneclick,
    headingExpValue,
    upsellName,
    complianceTest,
  }: {
    place:
      | 'signup_multioffer_upsell'
      | 'signup_singleoffer_upsell'
      | 'signup_gigaupsell';
    offerId: string;
    pricingId: string;
    price: number;
    currency: string;
    paywall_config_id: string;
    is_oneclick: boolean;
    headingExpValue?: string | null;
    upsellName?: string;
    complianceTest?: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place,
      feature:                       'signup',
      action:                        'apple_pay_loader',
      paywall_config_id,
      offer_id:                      offerId,
      pricing_id:                    pricingId,
      price,
      currency,
      language:                      i18next.language,
      payment_method:                'APPLEPAY',
      is_oneclick,
      exp_headings_value_v2:         headingExpValue,
      name:                          upsellName,
      exp_upsell_compliant_first_v1: complianceTest,
    });
  },
  onSkip({
    position,
    place,
    paymentMethod,
    paywall_config_id,
    exp_upsell_megaupsell,
    exp_upsell_upsell_cascade_redesign,
    is_oneclick,
    headingExpValue,
    upsellName,
    complianceTest,
  }: {
    position: 'top' | 'bottom';
    paymentMethod: string;
    place:
      | 'signup_multioffer_upsell'
      | 'signup_singleoffer_upsell'
      | 'signup_gigaupsell';
    paywall_config_id: string;
    exp_upsell_megaupsell: string;
    exp_upsell_upsell_cascade_redesign?: boolean;
    is_oneclick: boolean;
    headingExpValue?: string | null;
    upsellName?: string;
    complianceTest?: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      place,
      paywall_config_id,
      feature:                       'signup',
      action:                        'skip',
      position,
      language:                      i18next.language,
      payment_method:                paymentMethod,
      exp_upsell_second_prosperi_v1: exp_upsell_megaupsell,
      exp_upsell_upsell_cascade_redesign,
      is_oneclick,
      exp_headings_value_v2:         headingExpValue,
      name:                          upsellName,
      exp_upsell_compliant_first_v1: complianceTest,
    });
  },
  onAllUpsellSkip(props: {
    exp_upsell_megaupsell: string;
    is_oneclick: boolean;
    headingExpValue?: string | null;
    complianceTest?: string;
  }) {
    analyticsService.sendEvent('webapp_upsell_click', {
      feature:                       'signup',
      action:                        'unavailable_apple_pay',
      language:                      i18next.language,
      exp_upsell_second_prosperi_v1: props.exp_upsell_megaupsell,
      is_oneclick:                   props.is_oneclick,
      exp_headings_value_v2:         props.headingExpValue,
      exp_upsell_compliant_first_v1: props.complianceTest,
    });
  },
};
