import { useTranslation } from 'react-i18next';

import FlagIcon from '@/modules/guides/widgets/guide-lesson-widget/ui/assets/FlagIcon';

import { UiPrimaryButton } from '../../../../shared/ui';

import './guide-feedback.css';

export const GuideErrorFeedback = ({
  feedback,
  onRetry,
  onReport,
}: {
  feedback: string;
  onRetry: () => void;
  onReport?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="guide-feedback guide-error-feedback">
      <div className="guide-feedback__header justify-between">
        <div className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
          >
            <rect
              width="19"
              height="19"
              x=".5"
              y=".5"
              fill="#D43333"
              rx="9.5"
            />
            <rect
              width="19"
              height="19"
              x=".5"
              y=".5"
              stroke="#D43333"
              rx="9.5"
            />
            <path
              fill="#fff"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              // eslint-disable-next-line max-len
              d="M6.087 6.087a.583.583 0 0 1 .825 0L10 9.175l3.087-3.088a.583.583 0 1 1 .825.825L10.825 10l3.087 3.087a.583.583 0 1 1-.825.825L10 10.825l-3.088 3.087a.583.583 0 0 1-.825-.825L9.175 10 6.087 6.912a.583.583 0 0 1 0-.825Z"
            />
          </svg>

          <h4 className="guide-feedback__title">
            {t('guides.lesson-chat.feedback.error.title')}
          </h4>
        </div>
        {onReport && (
          <FlagIcon
            onClick={onReport}
            className="text-[#d43333] cursor-pointer"
          />
        )}
      </div>

      <p className="guide-feedback__feedback">{feedback}</p>

      <UiPrimaryButton
        className="guide-feedback__button guide-error-feedback__button"
        onClick={onRetry}
      >
        {t('guides.lesson-chat.feedback.error.button')}
      </UiPrimaryButton>
    </div>
  );
};
