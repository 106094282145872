import { useState } from 'react';

import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import dayjs from 'dayjs';

import { useWhatsNew } from '@/entities/whats-new';
import { GuidesMainPage } from '@/modules/guides';
import { GuidesMainPageV2 } from '@/modules/guides-v2';
import useAiPathwayExperiment from '@/shared/lib/hooks/use-ai-pathway-experiment';
import useCertificatesExperiment from '@/shared/lib/hooks/use-certificates-experiment';
import { AiToolsUpdatesBottomSheet } from '@/shared/ui/ai-tools-updates-bottom-sheet';

export const MainPage = () => {
  const growthbook = useGrowthBook();
  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });
  const {
    isWhatsNewVisible: isAiToolsWhatsNewVisible,
    setWhatsNewHidden: setAiToolsWhatsNewViewed,
  } = useWhatsNew('certificates');
  const [isAiToolsWhatsNewClosed, setIsAiToolsWhatsNewClosed] = useState(false);

  const certificatesReleaseDate = useFeatureValue('certificates_release_date', null);

  const { registered_at: registeredAt } = growthbook?.getAttributes() ?? {};
  const { isTest: isCertificateTestEnabled } = useCertificatesExperiment();

  const isPathwayEnabled = aiPathwayExperiment === 'test';

  const isUpdatesBottomSheetVisible = Boolean(
    !isCertificateTestEnabled
      && isAiToolsWhatsNewVisible
      && !isAiToolsWhatsNewClosed
      && registeredAt
      && certificatesReleaseDate
      && isPathwayEnabled
      // Type casting because registeredAt is type of any
      && dayjs(registeredAt as string).isBefore(dayjs(certificatesReleaseDate)),
  );

  const onAiToolsWhatsNewClose = () => {
    setIsAiToolsWhatsNewClosed(true);
    setAiToolsWhatsNewViewed();
  };

  return (
    <>
      {isPathwayEnabled ? (
        <GuidesMainPageV2 />
      ) : (
        <GuidesMainPage />
      )}
      <AiToolsUpdatesBottomSheet
        isVisible={isUpdatesBottomSheetVisible}
        onClose={onAiToolsWhatsNewClose}
      />
    </>
  );
};

export default MainPage;
