import type { TFunction } from 'i18next';

import classNames from 'classnames';

import './assets/more-button.scss';

export const MoreButton = ({
  children,
  disabled,
  onClick,
  className,
}: {
  disabled?: boolean;
  children: string | ReturnType<TFunction> | JSX.Element | JSX.Element[];
  className?: string;
  onClick?: () => void;
}) => (
  <button
    type="button"
    disabled={disabled}
    className={classNames(
      'more-button',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
