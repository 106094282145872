import { useTranslation } from 'react-i18next';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { GuideType } from '../../../../guides/entities/guide';
import { useGuidesOnboarding } from '../../../../guides/entities/guides-onboarding';
import { UiPrimaryButton } from '../../../../shared/ui';
import { CheckMarkProgress } from '../../../entites/checkmark-progress';
import './ongoing-guide-v2.scss';

export const OngoingGuideV2 = ({
  guide,
  progress,
  onContinue,
}: {
  guide: GuideType;
  progress: number;
  onContinue: (guide: GuideType, isPathway: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { isGuidesOnboardingVisible } = useGuidesOnboarding();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const localizedTitle = arrow
    ? `guides-v2.${arrow}-arrow`
    : 'guides-v2.ai-arrow';

  return (
    <div className="ongoing-guide-wrapper">
      <div className="ongoing-guide bg-white">
        <div className="ongoing-guide-v2__image-container">
          <img
            width="124"
            height="124"
            src={guide.image}
            role="presentation"
            alt=""
          />
        </div>

        <p className="ongoing-guide-v2__category">{t(localizedTitle).toUpperCase()}</p>

        <h1 className="ongoing-guide__title">{guide.name}</h1>
        <CheckMarkProgress progress={progress} />

        <div className="ongoing-guide__actions">
          {isGuidesOnboardingVisible && (
            <div className="ongoing-guide__tip">{t('guides.tip-text')}</div>
          )}

          <UiPrimaryButton onClick={() => onContinue(guide, true)}>
            {t('guides.continue-learning-button')}
          </UiPrimaryButton>
        </div>
      </div>
    </div>
  );
};
