import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import { useRecoilValue } from 'recoil';

import { analyticsService } from '@/services/analytics.service';
import { getOsWithVersion } from '@/shared/lib';

import { userQuery } from '../../../context/atoms/User';
import useAssistantUpsellExperiment from '../../../hooks/use-assistant-upsell-experiment';
import { usePrefetchCurrencySignQuery } from '../../../hooks/use-currency-sign-query';
import i18n from '../../../i18n';
import { getQueryParamsFromURI } from '../../../utils/QueryParams/QueryParams';
import LanguageNavbar from '../../shared/Navbar/LanguageNavbar';
import { useUpsellCascadePrefetch, useUpsellSinglePrefetch } from '../../upsell';
import { useGigaUpsellContent } from '../../upsell/upsell-single/entities/giga-upsell-content/model';

import { CreateAccountComponent } from './CreateAccountComponent/CreateAccountComponent';
import { getArrowByPrcId } from './lib/utils';
import ProgressComponent from './ProgressBar/ProgressBar';
import { progress_steps } from './ProgressBarData';
import {
  ContainerWithReviews,
  PositionedPageContainer,
  Wrapper,
} from './Signup.styles';

export const SignUpPage = () => {
  const navigation = useHistory();
  const user = useRecoilValue(userQuery);
  const queryParams = getQueryParamsFromURI();

  const selectedArrow = getArrowByPrcId(queryParams.prc_id);

  const { value: assistantUpsellTestValue } = useAssistantUpsellExperiment({
    arrow: selectedArrow,
  });

  const upsellName = useFeatureValue('giga_upsell_feature', 'default');

  const growthbook = useGrowthBook();

  const { prefetchUpsellCascadeQuery } = useUpsellCascadePrefetch();
  const { prefetchUpsellSingleQuery } = useUpsellSinglePrefetch();
  const { prefetchCurrencyQuery } = usePrefetchCurrencySignQuery();
  const { data: upsellData } = useGigaUpsellContent(
    upsellName || 'default',
    i18n.language,
  );

  useEffect(() => {
    if (user?.user_id) {
      growthbook?.setAttributes({
        id: user.user_id.toString(),
      });
    }
  }, [user]);

  useEffect(() => {
    prefetchUpsellCascadeQuery();
  }, []);

  useEffect(() => {
    prefetchCurrencyQuery();
  }, [i18n.language]);

  useEffect(() => {
    const defaultPaywallConfigId = 'giga_upsell';

    prefetchUpsellSingleQuery({
      paywallConfigId: upsellData?.paywallConfigId || defaultPaywallConfigId,
    });
  }, [assistantUpsellTestValue, selectedArrow, upsellData?.paywallConfigId]);

  function onGoToNextPage(assisstantTestValue?: string) {
    const nextPageUrl = `/upsell-single-giga/${upsellName}`;

    analyticsService.setAmplitudeUserProperties({
      web_os_version: getOsWithVersion(),
    });

    navigation.replace(nextPageUrl);
  }

  return (
    <PositionedPageContainer>
      <LanguageNavbar
        isShadow={false}
        isSticky={false}
        isLogoRedirects={false}
      />

      <Wrapper>
        <ContainerWithReviews>
          <ProgressComponent
            steps={progress_steps}
            currentStep={2}
          />

          <CreateAccountComponent
            assistantTest={assistantUpsellTestValue}
            selectedArrow={selectedArrow}
            events={{
              onGoToNextPage,
            }}
          />
        </ContainerWithReviews>
      </Wrapper>
    </PositionedPageContainer>
  );
};
