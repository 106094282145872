import { analyticsService } from '../../../../../../services/analytics.service';

export const ProfileCertificates = {
  onCertificateOpen({ guideId }: { guideId: string }) {
    analyticsService.sendEvent('webapp_profile_click', {
      action: 'open_certificates',
      guide_id: guideId,
    });
  },

  onBottomSheetView() {
    analyticsService.sendEvent('webapp_profile_view', {
      feature: 'profile_page',
      place: 'personalize_sheet',
    });
  },

  onCertificateSubmit() {
    analyticsService.sendEvent('webapp_certificate_click', {
      feature: 'personalize_sheet',
      place: 'profile_page',
      action: 'submit',
    });
  },

  onPersonalizeSheetClose() {
    analyticsService.sendEvent('webapp_certificate_click', {
      feature: 'profile_page',
      place: 'personalize_sheet',
      action: 'close',
    });
  },
  onCertificateProgressClick() {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_page',
      action: 'click_cert_progress',
    });
  },
};
