import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useTranslation } from 'react-i18next';

import { useApplePay } from '@/entities/apple-pay';
import { PAYMENT_METHODS } from '@/entities/payment-method';
import { megaUpsellView } from '@/entities/upsell/lib/megaUpsellAnalytics';
import { useCurrencySign } from '@/entities/upsell/lib/useCurrencySign';
import { ChaseBottomsheet, ChaseBottomSheetData } from '@/entities/upsell/ui/ChaseBottomSheet';
import { useCurrencySignQuery } from '@/hooks/use-currency-sign-query';
import { Loader } from '@/modules/shared';
import CustomSpinner from '@/modules/shared/CustomSpinner/CustomSpinner';
import { UpsellAnalytics } from '@/modules/upsell/shared/analytics';
import { useWebToAppAndroidExperiment } from '@/modules/upsell/shared/hooks/use-web-to-app-android-experiment';
import { useWebToAppExperiment } from '@/modules/upsell/shared/hooks/use-web-to-app-experiment';
import { getWebToAppUrl } from '@/modules/upsell/shared/lib';
import { UiHeader } from '@/modules/upsell/shared/ui';
import { useBuyUpsellOfferMutation, useUpsellSingleQuery } from '@/modules/upsell/upsell-single/model';
import { isAndroid, isIOS16OrLater } from '@/shared/lib';
import { formatPrice } from '@/shared/lib/price';

import { MegaUpsellContent } from './MegaUpsellContent';
import { MegaUpsellHeader } from './MegaUpsellHeader';
import { MegaUpsellPaywall } from './MegaUpsellPaywall';

import './assets/upsell.css';

export const MegaUpsellPage = () => {
  const { t } = useTranslation();
  const navigation = useHistory();
  const params = useParams<{ paywallConfigId: string }>();

  const { paywallConfigId } = params;
  const isWebToAppScreenShow = useFeatureIsOn('ios_web2app_screen_show');

  const webToAppExperiment = useWebToAppExperiment();
  const androidWebToAppExperiment = useWebToAppAndroidExperiment();

  const [isUpsellBottomSheetVisible, setIsUpsellBottomSheetVisible] =    useState(false);
  const [isDiscountUpsell, setIsDiscountUpsell] = useState(false);

  const {
    lastPaymentMethodQuery,
    isApplePayAvailableInSystem,
    isLastPaymentMethodApplePay,
    isApplePay,
    isOneClick,
  } = useApplePay();

  const upsellOfferSingleQuery = useUpsellSingleQuery({
    paywallConfigId,
  });

  const currencySignQuery = useCurrencySignQuery();

  const pricing = useMemo(() => {
    if (upsellOfferSingleQuery.data) {
      return isDiscountUpsell
        ? upsellOfferSingleQuery.data.discountedPrice
        : upsellOfferSingleQuery.data.initialPrice;
    }

    return null;
  }, [upsellOfferSingleQuery.data, isDiscountUpsell]);

  const isUpsellAvailable = useMemo(
    () => (isLastPaymentMethodApplePay ? isApplePayAvailableInSystem : true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLastPaymentMethodApplePay],
  );
  const upsellOfferSingleMutation = useBuyUpsellOfferMutation();

  const fullPrice = useMemo(
    () => (pricing && currencySignQuery.data
      ? `${currencySignQuery.data}${formatPrice(pricing.full_price)}`
      : ''),
    [pricing, currencySignQuery.data],
  );
  const currencySign = useCurrencySign(paywallConfigId);

  const discountedPrice = useMemo(
    () => (pricing && currencySignQuery.data
      ? `${currencySign}${formatPrice(pricing.discount_price)}`
      : ''),
    [pricing, currencySignQuery.data, currencySign],
  );

  const currencyCode = useMemo(
    () => (upsellOfferSingleQuery.data ? upsellOfferSingleQuery.data.currency : ''),
    [upsellOfferSingleQuery.data],
  );

  const discountedPercent = useMemo(
    () => pricing && pricing.discount,
    [pricing],
  );

  const isOfferPaywallConfig = paywallConfigId.includes('_single');

  const discountPercentOffText = t('mega-upsell.banner.off', {
    discountPercent: discountedPercent,
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const chaseData = t('mega-upsell.chase', {
    returnObjects: true,
  }) as ChaseBottomSheetData;

  function goToNextPage() {
    window.scroll(0, 0);

    if (isIOS16OrLater() && isWebToAppScreenShow) {
      navigation.push('/web-to-app');
    } else if (isAndroid() && androidWebToAppExperiment === 'test') {
      navigation.push('/web-to-app');
    } else {
      navigation.replace('/onboarding/v2');
    }
  }

  // eslint-disable-next-line react/no-unused-prop-types
  const onSkip = ({ position }: { position: 'top' | 'bottom' }) => {
    UpsellAnalytics.onSkip({
      position,
      place:             'signup_multioffer_upsell',
      paywall_config_id: isOfferPaywallConfig
        ? 'offer_single'
        : 'upsells_notion_report',
      paymentMethod:         lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: 'test',
      is_oneclick:           isOneClick,
      upsellName:            paywallConfigId,
    });

    if (isDiscountUpsell) {
      return goToNextPage();
    }

    setIsUpsellBottomSheetVisible(true);

    return null;
  };

  function onShowDiscountedUpsell() {
    setIsUpsellBottomSheetVisible(false);
    setIsDiscountUpsell(true);
    window.scroll(0, 0);
  }

  const onPurchaseNewUpsell = () => {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place:                 'signup_multioffer_upsell',
        pricingId:             pricing.id,
        offerId:               upsellOfferSingleQuery.data.id,
        price:                 pricing.discount_price,
        currency:              currencyCode,
        paywall_config_id:     paywallConfigId,
        paymentMethod:         lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: 'test',
        is_oneclick:           isOneClick,
        upsellName:            'default',
      });

      upsellOfferSingleMutation
        .mutateAsync({
          offerId:   upsellOfferSingleQuery.data.id,
          paywallConfigId,
          pricingId: pricing.id,
        })
        .catch((cause: unknown) => {
          throw new Error('ERROR_PURCHASE_NEW_UPSELL', { cause });
        })
        .finally(() => {
          setTimeout(() => {
            goToNextPage();
          }, 500);
        });
    }
  };

  function onApplePaySuccess() {
    setTimeout(() => {
      goToNextPage();
    }, 500);
  }

  function onApplePayTryToPay() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place:                 'signup_multioffer_upsell',
        pricingId:             pricing.id,
        offerId:               upsellOfferSingleQuery.data.id,
        price:                 pricing.discount_price,
        currency:              currencyCode,
        paywall_config_id:     paywallConfigId,
        paymentMethod:         PAYMENT_METHODS.APPLE_PAY,
        exp_upsell_megaupsell: 'test',
        is_oneclick:           isOneClick,
      });
    }
  }

  function onApplePayLoaderClick() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onApplePayLoaderClick({
        place:             'signup_multioffer_upsell',
        pricingId:         pricing.id,
        offerId:           upsellOfferSingleQuery.data.id,
        price:             pricing.discount_price,
        currency:          currencyCode,
        paywall_config_id: paywallConfigId,
        is_oneclick:       isOneClick,
      });
    }
  }

  useEffect(() => {
    if (
      !(
        upsellOfferSingleQuery.data
    && pricing
    && !lastPaymentMethodQuery.isLoading
    && isUpsellAvailable
      )
    ) {
      return;
    }

    megaUpsellView({
      offerId:           upsellOfferSingleQuery.data.id,
      pricingId:         pricing.id,
      price:             pricing.discount_price,
      currency:          currencyCode,
      paywall_config_id: paywallConfigId,
      paymentMethod:     lastPaymentMethodQuery.data?.payment_type ?? 'error',
      isOneClick,
    });

    UpsellAnalytics.legacyPageView({
      price:    pricing.discount_price,
      currency: currencyCode,
      isDiscountUpsell,
      isOneClick,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    upsellOfferSingleQuery.data?.id,
    pricing?.id,
    isUpsellAvailable,
    lastPaymentMethodQuery.data?.payment_type,
    isOfferPaywallConfig,
  ]);
  useEffect(() => {
    if (lastPaymentMethodQuery.isLoading || isUpsellAvailable) {
      return;
    }

    UpsellAnalytics.onAllUpsellSkip({
      exp_upsell_megaupsell: 'test',
      is_oneclick:           isOneClick,
    });

    if (isIOS16OrLater() && isWebToAppScreenShow) {
      navigation.replace(getWebToAppUrl(webToAppExperiment));
    } else {
      navigation.replace('/onboarding/v2');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpsellAvailable, lastPaymentMethodQuery.isLoading]);

  if (lastPaymentMethodQuery.isLoading || !isUpsellAvailable) {
    return <Loader />;
  }

  return (
    <>
      {upsellOfferSingleMutation.isLoading && <CustomSpinner />}

      <main className="upsell vh-full">
        <UiHeader
          events={{
            onSkip: () => onSkip({ position: 'top' }),
          }}
        />
        <MegaUpsellHeader
          discountPercentOffText={discountPercentOffText}
          discountedPrice={discountedPrice}
          fullPrice={fullPrice}
        />
        <MegaUpsellContent
          discountedPercentOffText={discountPercentOffText}
          discountedPrice={discountedPrice}
          isDiscountUpsell={isDiscountUpsell}
        />
        {upsellOfferSingleQuery.data && pricing && (

          <MegaUpsellPaywall
            upsellOfferData={upsellOfferSingleQuery.data}
            pricing={pricing}
            paywallConfigId={paywallConfigId}
            isApplePay={isApplePay}
            onPurchaseNewUpsell={onPurchaseNewUpsell}
            onApplePayTryToPay={() => onApplePayTryToPay()}
            onApplePayLoaderClick={() => onApplePayLoaderClick()}
            onApplePaySuccess={() => onApplePaySuccess()}
            onSkip={onSkip}
          />
        )}
      </main>

      <ChaseBottomsheet
        isVisible={isUpsellBottomSheetVisible}
        events={{
          onClose: onShowDiscountedUpsell,
        }}
        data={chaseData}
      />
    </>
  );
};
