import { Trans } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { ImageSkeleton } from '@/modules/guides/features/image-skeleton';
import { UiPrimaryButton } from '@/modules/shared/ui';

import './assets/chase-bottomsheet.scss';

export interface ChaseBottomSheetData {
    title: string;
    description: string;
    quote: string;
    button: string;
    image: string;
}

export interface ChaseBottomSheetProps {
  isVisible: boolean;
  events: {
    onClose: () => void;
  };
  data: ChaseBottomSheetData;
}

export const ChaseBottomsheet = ({
  isVisible,
  events,
  data,
}: ChaseBottomSheetProps) => (
  <BottomSheet
    key="new-upsell-chase-bottomsheet"
    open={isVisible}
  >
    <div className="upsell-chase-bottomsheet">
      <div className="upsell-chase-bottomsheet__container">
        <h1 className="upsell-chase-bottomsheet__title">
          {data.title}
        </h1>
        <p className="upsell-chase-bottomsheet__description">
          <Trans
            i18nKey={data.description}
            components={{ b: <b /> }}
          />
        </p>
        <ImageSkeleton
          className="upsell-chase-bottomsheet__image"
          width="100%"
          height="218px"
          src={data.image}
          alt=""
        />
        <div
          className="upsell-chase-bottomsheet__quote"
        >
          <Trans
            i18nKey={data.quote}
            components={{ b: <b className="upsell-chase-bottomsheet__quote__key-words" /> }}
          />
        </div>
        <div className="upsell-chase-bottomsheet__actions">
          <UiPrimaryButton onClick={events.onClose}>
            {data.button}
          </UiPrimaryButton>
        </div>
      </div>
    </div>
  </BottomSheet>
);
