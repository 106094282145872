import { UpsellOffer } from '@/types/Offers';

import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { ApplePayButton } from '@/entities/apple-pay';
import { UiPrimaryButton } from '@/modules/shared/ui';

import { Pricing } from '../model/pricing';

interface MegaUpsellPaywallProps {
  upsellOfferData: UpsellOffer;
  pricing: Pricing;
  paywallConfigId: string;
  isApplePay: boolean;
  onPurchaseNewUpsell: () => void;
  onApplePayTryToPay: () => void;
  onApplePayLoaderClick: () => void;
  onApplePaySuccess: () => void;
  onSkip: ({ position }: {position: 'top' | 'bottom'}) => void;
}

export const MegaUpsellPaywall: React.FC<MegaUpsellPaywallProps> = ({
  upsellOfferData,
  pricing,
  paywallConfigId,
  isApplePay,
  onPurchaseNewUpsell,
  onApplePayTryToPay,
  onApplePayLoaderClick,
  onApplePaySuccess,
  onSkip,
}) => {
  const { t } = useTranslation();

  const buyButtonRef = useRef<HTMLDivElement>(null);
  const [isTopButtonVisible, setIsTopButtonVisible] = useState(true);

  const scrollToBottom = () => {
    window.scrollTo({
      top:      document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const intersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.target === buyButtonRef.current) {
        setIsTopButtonVisible(entry.isIntersecting);
      }
    });
  };

  useEffect(() => {
    if (buyButtonRef.current) {
      const intersectionObserver = new IntersectionObserver(intersectionObserverCallback, {
        root:       null,
        rootMargin: '0px',
        threshold:  1.0,
      });

      intersectionObserver.observe(buyButtonRef.current);

      return () => {
        intersectionObserver.disconnect();
      };
    }

    return undefined;
  }, []);

  return (
    <>
      {upsellOfferData && (
        <div
          className="upsell_purchase-button static"
          ref={buyButtonRef}
          style={{ opacity: isTopButtonVisible ? 1 : 0 }}
        >
          {isApplePay && pricing && (
            <ApplePayButton
              offerId={upsellOfferData.id}
              pricingId={pricing.id}
              paywallConfigId={paywallConfigId}
              events={{
                onTryToPay:    onApplePayTryToPay,
                onLoaderClick: onApplePayLoaderClick,
                onSuccess:     onApplePaySuccess,
              }}
            />
          )}

          {!isApplePay && (
            <UiPrimaryButton
              className="upsell__add-button"
              onClick={onPurchaseNewUpsell}
            >
              {t('upsell-single.purchase-button')}
            </UiPrimaryButton>
          )}
        </div>
      )}

      {upsellOfferData && pricing && !isTopButtonVisible && (
        <div className="upsell_purchase-button upsell-giga_purchase-bottom-button">
          <UiPrimaryButton
            className="upsell__add-button"
            onClick={scrollToBottom}
          >
            {t('upsell-single.purchase-button')}
          </UiPrimaryButton>
        </div>
      )}
      <div className="upsell__skip">
        <button
          type="button"
          className="upsell__skip-button"
          onClick={() => onSkip({ position: 'bottom' })}
        >
          {t('upsell-single.skip-button')}
        </button>
      </div>
    </>
  );
};
