import { Fragment } from 'react';

import SeparatorIcon from '@/modules/guides-v2/shared/assets/svg/sepator-icon.svg';

interface ArrowGuidesCardsSkeletonProps {
    cardsLength: number;
}

export const ArrowGuidesCardsSkeleton = ({ cardsLength }: ArrowGuidesCardsSkeletonProps) => {
  const mockArray = Array(cardsLength).fill(null);

  return (
    <div className="px-4 flex flex-col gap-y-3 mt-6">
      {mockArray.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <div className="w-full h-[100px] bg-gray-200 animate-pulse rounded-lg" />

          {index < mockArray.length - 1 && (
            <div className="guide-certifcate__separator mt-0">
              <img
                src={SeparatorIcon}
                alt="separator icon"
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
