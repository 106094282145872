import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { onPathwayMenuClick } from '@/modules/guides-v2/entites/path-details/lib/analytics';

import { usePersonalProfileQuery } from '../../../../../../entities/personal-profile';
import { GuideUnitType } from '../../../../../guides/entities/guide';
import { Toast } from '../../../../pages/learning-pathway/ui/LearningPathway';

import MenuIcon from './assets/MenuIcon';

interface PathwayNavigationToastProps {
  toastValue: Toast | undefined;
  toastRef: React.RefObject<HTMLDivElement>;
  isToolsOpen: boolean;
  onToastClick: (unit: GuideUnitType | undefined) => void;
}

export const PathwayNavigationToast = ({
  toastValue,
  toastRef,
  isToolsOpen,
  onToastClick,
}: PathwayNavigationToastProps) => {
  const { t } = useTranslation();
  const navigation = useHistory();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const localizedTitle = arrow
    ? `guides-v2.${arrow}-arrow`
    : 'guides-v2.ai-arrow';

  const onMenuClick = () => {
    onPathwayMenuClick();

    navigation.push('/path-details');
  };

  return (
    <div
      className={classNames('pathway-navigation-toast-container', {
        'pathway-navigation-toast-container__active': isToolsOpen,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className="pathway-navigation-toast flex justify-between items-center"
        ref={toastRef}
        onClick={() => onToastClick(toastValue?.unit)}
      >
        <div className="p-4">
          <div className="pathway-navigation-toast__details">
            <p>{t(localizedTitle).toUpperCase()}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="5"
              fill="none"
            >
              <circle
                cx="2.5"
                cy="2.5"
                r="2"
                fill="#FFFFFF"
              />
            </svg>
            <p>
              {toastValue?.guideName}
              :
              {t('guides-v2.level')}
              {' '}
              {toastValue?.unit.ordering}
            </p>
          </div>
          <h4 className="pathway-navigation-toast__heading">
            {toastValue?.unit.name}
          </h4>
        </div>
        <button
          type="button"
          onClick={() => onMenuClick()}
          className="px-4 h-full flex justify-center items-center border-l-[2px] border-[#4542CB]"
        >
          <MenuIcon />
        </button>
      </div>
    </div>
  );
};
