import {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { UiPrimaryButton } from '@/modules/shared/ui';
import CloseIcon from '@/shared/ui/assets/svg/close.svg';

import { IssueType } from '../types';
import './report-bug-bottom-sheet.scss';

export const ReportBugBottomSheet = ({
  isVisible,
  onClose,
  events,
}: {
  isVisible: boolean;
  onClose: () => void;
  events: {
    onView: () => void;
    onSubmit: ({ issues, textFeedback }: {issues: string[]; textFeedback: string}) => void;
  };
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'guides.report_bug' });
  const [selectedIssues, setSelectedIssues] = useState<IssueType[]>([]);
  const [description, setDescription] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const issueTypes: Array<{ id: IssueType; labelKey: string }> = [
    { id: IssueType.SPELLING_GRAMMAR, labelKey: 'issues.spelling_grammar' },
    { id: IssueType.OUTDATED_CONTENT, labelKey: 'issues.outdated_content' },
    { id: IssueType.TRANSLATION_ERROR, labelKey: 'issues.translation_error' },
    { id: IssueType.INCORRECT_ANSWER, labelKey: 'issues.incorrect_answer' },
    { id: IssueType.OTHER, labelKey: 'issues.other' },
  ];

  const handleIssueToggle = (issueType: IssueType) => {
    setSelectedIssues(prev => (prev.includes(issueType) ? prev.filter(i => i !== issueType) : [...prev, issueType]));
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);

    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const onSubmit = () => {
    if (selectedIssues) {
      const labelKeys = selectedIssues.map(id => issueTypes.find(type => type.id === id)?.labelKey).filter(Boolean);

      const translatedLabels = labelKeys.map(labelKey => t(labelKey || ''));

      events.onSubmit({ issues: translatedLabels, textFeedback: description });
    }

    setSelectedIssues([]);
    setDescription('');

    onClose();
  };

  const isOtherSelected = useMemo(() => selectedIssues.includes(IssueType.OTHER), [selectedIssues]);

  useEffect(() => {
    if (isVisible) {
      events.onView();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <BottomSheet
      key="report-bug-bottomsheet"
      style={{ zIndex: 999999 }}
      open={isVisible}
      onDismiss={onClose}
    >
      <div className="max-w-[500px] mx-auto px-4 py-3 pb-6">
        <div>
          <div className="flex w-full justify-between">
            <h1 className="text-xl font-bold">{t('title')}</h1>
            <button
              type="button"
              className="explore-new-bots__close-button"
              onClick={onClose}
            >
              <img
                src={CloseIcon}
                alt={t('close_button_alt') || ''}
              />
            </button>
          </div>
          <p className="text-base mt-2">{t('subtitle')}</p>

          <div className="mt-4 flex flex-col gap-y-3">
            {issueTypes.map(({ id, labelKey }) => (
              <div
                key={id}
                className="flex items-center gap-2 mt-2"
              >
                <input
                  type="checkbox"
                  className="report-bug-bottom-sheet__checkbox"
                  id={id}
                  name="issueType"
                  value={id}
                  checked={selectedIssues.includes(id)}
                  onChange={() => handleIssueToggle(id)}
                />
                <label htmlFor={id}>{t(labelKey)}</label>
              </div>
            ))}
          </div>

          <div
            className="grid transition-[grid-template-rows] duration-300 ease-in-out"
            style={{
              gridTemplateRows: isOtherSelected ? '1fr' : '0fr',
              marginTop:        isOtherSelected ? '1rem' : 0,
            }}
          >
            <div className="overflow-hidden">
              <textarea
                ref={textareaRef}
                value={description}
                onChange={handleTextareaChange}
                placeholder={t('description_placeholder') || ''}
                className="w-full p-2 border rounded transition-[opacity,transform]
                          duration-300 ease-in-out focus:outline-none"
                style={{
                  opacity:   isOtherSelected ? 1 : 0,
                  transform: isOtherSelected ? 'translateY(0)' : 'translateY(-8px)',
                  resize:    'none',
                }}
                rows={4}
              />
            </div>
          </div>

          <div className="mt-4">
            <UiPrimaryButton onClick={onSubmit}>
              {t('submit_button')}
            </UiPrimaryButton>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};
