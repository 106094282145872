import classNames from 'classnames';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  GuideDetailsType,
  GuideLessonType,
  GuideUnitType,
} from '../../../../guides/entities/guide';
import { GuideProgressByIdType } from '../../../../guides/entities/guide-progress';
import { ReturnLessonEnd, ReturnUnitEnd } from '../../../../guides/shared/lib';
import { GuideTarget } from '../../../entites/guide-target';
import { LessonVector } from '../../../entites/lesson-vector';
import { Toast } from '../../../pages/learning-pathway/ui/LearningPathway';
import { GuidesV2Analytics } from '../../../shared/analytics';
import { getIsLastLesson } from '../../../shared/lib';
import { getFirstNotCompletedLesson } from '../../../shared/lib/get-first-not-completed-lesson';
import { PathwayCoasts } from './PathwayCoasts';
import './assets/pathway-units.css';

interface PathwayUnitsProps {
  guide: GuideDetailsType;
  progress: GuideProgressByIdType;
  nextLesson: ReturnLessonEnd | ReturnUnitEnd | null;
  setToastValue: React.Dispatch<React.SetStateAction<Toast | undefined>>;
  toastRef: React.RefObject<HTMLDivElement>;
}

export function PathwayUnits({
  guide,
  progress,
  nextLesson,
  setToastValue,
  toastRef,
}: PathwayUnitsProps) {
  const [selectedLessonId, setSelectedLessonId] = useState(
    guide.content[0].content[0].id
  );
  const [tooltipValue, setTooltipValue] = useState<GuideLessonType>();
  const [isTargetVisible, setIsTargetVisible] = useState(false);

  const unitRefs = useRef<(HTMLDivElement | null)[]>([]);
  const titleRefs = useRef<(HTMLDivElement | null)[]>([]);
  const activeUnit = useRef<HTMLDivElement>(null);

  const navigation = useHistory();


  const isLastLesson = useMemo(
    () => getIsLastLesson(progress),
    [progress]
  );
  const isGuideCompleted = useMemo(() => progress.progress === 1, [progress]);

  function getUnitProgress(
    progress: GuideProgressByIdType,
    id: GuideUnitType['id']
  ) {
    return progress.units[id];
  }

  useEffect(() => {
    if (progress.progress === 1) {
      return setSelectedLessonId('');
    }
    if (nextLesson) {
      const unitProgress = getUnitProgress(progress, nextLesson.unitId);
      if (unitProgress.lessons[nextLesson.lessonId].status !== 'completed') {
        return setSelectedLessonId(nextLesson.lessonId);
      }
    }

    setSelectedLessonId(getFirstNotCompletedLesson(guide, progress).id);
  }, [nextLesson, guide]);

  function getVectorStrokeColor(
    unitId: GuideUnitType['id'],
    lesson: GuideLessonType
  ) {
    const unitProgress = getUnitProgress(progress, unitId);
    const status = unitProgress.lessons[lesson.id].status;

    if (status === 'completed') {
      return '#19AA32';
    }
    return '#E2E5E9';
  }
  function getConvertedLessonStatus(
    unit: GuideUnitType,
    lesson: GuideLessonType
  ): 'completed' | 'not_completed' {
    const unitProgress = getUnitProgress(progress, unit.id);
    const lessonStatus = unitProgress.lessons[lesson.id].status;
    return lessonStatus === 'closed' || lessonStatus === 'not_started'
      ? 'not_completed'
      : 'completed';
  }

  function scrollToLesson(lesson: GuideLessonType) {
    const lessonClassName = `learning-pathway-content__item__coast__${lesson.id}`;

    const element = document.querySelector(`.${lessonClassName}`);
    if (element instanceof HTMLElement) {
      const elementRect = element.getBoundingClientRect();
      const middle = elementRect.top + window.scrollY - window.innerHeight / 2;
      window.scrollTo({ top: middle, behavior: 'smooth' });
    }
  }

  function onLessonClick(unit: GuideUnitType, lesson: GuideLessonType) {
    setTooltipValue(lesson);
    const convertedLessonStatus = getConvertedLessonStatus(unit, lesson);

    GuidesV2Analytics.onLessonCoastClick({
      guide_id: guide.id,
      unit_id: unit.id,
      lesson_id: lesson.id,
      state: convertedLessonStatus,
    });

    scrollToLesson(lesson);
  }

  function onGoToLesson({
    unit,
    lesson,
  }: {
    unit: GuideUnitType;
    lesson: GuideLessonType;
  }) {
    const convertedLessonStatus = getConvertedLessonStatus(unit, lesson);
    const isLessonComplete = convertedLessonStatus === 'completed';

    GuidesV2Analytics.onStartLessonClick({
      guide_id: guide.id,
      unit_id: unit.id,
      lesson_id: lesson.id,
      state: convertedLessonStatus,
    });

    navigation.push(
      `/guides/${guide.id}/${unit.id}/${lesson.id}?pathway=test&isLastLesson=${
        isLastLesson && !isLessonComplete
      }`
    );
  }

  function onTooltipClose() {
    setTooltipValue(undefined);
  }

  function onTargetClick() {
    scrollToSelectedLesson();
    setTimeout(() => {
      setIsTargetVisible(false);
    }, 800);
  }

  function checkIfHeaderIsOverlapping() {
    if (!isTargetVisible) {
      setIsTargetVisible(true);
    }

    const toastRect = toastRef.current?.getBoundingClientRect();
    if (!toastRect) return;

    const checkOverlap = (ref: HTMLDivElement | null) => {
      if (!ref) return false;
      const rect = ref.getBoundingClientRect();
      return (
        toastRect.top <= rect.top + rect.height &&
        toastRect.top + toastRect.height > rect.top
      );
    };

    const updateToast = (ref: HTMLDivElement | null) => {
      const unitId = ref?.getAttribute('data-unit-id');
      const unit = guide.content.find(unit => unit.id === unitId);
      if (unit) {
        setToastValue({
          guideName: guide.name,
          unit: unit as GuideUnitType,
        });
      }
    };

    const overlappingRef =
      titleRefs.current.find(checkOverlap) ||
      unitRefs.current.find(checkOverlap);

    if (overlappingRef) {
      updateToast(overlappingRef);
    }
  }

  function getNextUnit(unit: GuideUnitType) {
    const unitIndex = guide.content.findIndex(u => u.id === unit.id);
    const nextUnit = guide.content[unitIndex + 1];
    if (nextUnit) {
      return nextUnit;
    }
    return unit;
  }
  function scrollToSelectedLesson() {
    if (activeUnit.current) {
      const selectedLesson = guide.content
        .flatMap(unit => unit.content)
        .find(lesson => lesson.id === selectedLessonId);
      if (selectedLesson) {
        scrollToLesson(selectedLesson);
      }
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', checkIfHeaderIsOverlapping);
    }
    watchScroll();

    return () => {
      window.removeEventListener('scroll', checkIfHeaderIsOverlapping);
    };
  });

  useEffect(() => {
    if (isGuideCompleted) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }

    scrollToSelectedLesson();
  }, [selectedLessonId]);

  return (
    <>
      <div style={{ height: '190px', visibility: 'hidden' }}></div>
      {isTargetVisible && !isGuideCompleted && (
        <GuideTarget onClick={onTargetClick} />
      )}

      <div
        className="learning-pathway__container"
        style={{ position: 'relative' }}
      >
        {guide.content.map((unit, i) => (
          <Fragment key={unit.id}>
            {unit.ordering !== 1 && (
              <div
                className={classNames(
                  'learning-pathway-separator',
                  `separator_${unit.id}`
                )}
              >
                {unit.name}
              </div>
            )}
            <div>
              <div className="learning-pathway-content">
                {unit.content.map((lesson, j) => (
                  <div
                    className="learning-pathway-content__item"
                    key={`${unit.id}-${lesson.id}`}
                  >
                    <LessonVector
                      unitId={unit.id}
                      lesson={lesson}
                      selectedLessonId={selectedLessonId}
                      getVectorStrokeColor={getVectorStrokeColor}
                    />
                  </div>
                ))}
              </div>
              <PathwayCoasts
                unit={unit}
                progress={progress}
                selectedLessonId={selectedLessonId}
                tooltipValue={tooltipValue}
                unitRefs={unitRefs}
                titleRefs={titleRefs}
                activeUnitRef={activeUnit}
                unitIndex={i}
                onLessonClick={onLessonClick}
                onTooltipClose={onTooltipClose}
                onGoToLesson={onGoToLesson}
                getUnitProgress={getUnitProgress}
                getNextUnit={getNextUnit}
              />
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
}
