import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { GuideType } from '@/modules/guides/entities/guide';

interface PathCardProps {
    guide: GuideType;
    progress: number;
    onClick?: () => void;
}

export const PathCard = ({ guide, progress, onClick }: PathCardProps) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={onClick}
      className="flex w-full p-4 bg-white items-center rounded-lg border-fill-primary border-[1px] border-b-4"
    >
      <img
        src={guide.image}
        alt="Guide Logo"
        className="w-[56px] h-[56px] mr-3"
      />
      <div className="w-full">
        <strong className="guides-list-arrow__item-title mt-0">
          {guide.name}
        </strong>
        <div className="guides-list-arrow__item-duration">
          <p>
            {t('guides-v2.lessons')}
            {' '}
            {guide.lessons_count}
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="5"
            fill="none"
          >
            <circle
              cx="2.5"
              cy="2.5"
              r="2"
              fill="#565B66"
            />
          </svg>
          <p>
            {guide.units_count}
            {' '}
            {t('guides-v2.levels')}
          </p>
        </div>
        <div className="guides-list-arrow__progress mt-2">
          <div
            className={classNames('guides-list-arrow__progress-line')}
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </button>
  );
};
