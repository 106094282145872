import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { MoreButton } from '@/shared/ui/MoreButton';

import { UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import { GuideType } from '../../../../guides/entities/guide';

import './assets/guides-other-small-list.css';

export const GuidesOtherSmallListLoading = () => {
  const { t } = useTranslation();

  return (
    <section className="guides-other-small-list">
      <h1 className="guides-other-small-list__title">
        {t('guides.recommends.title')}
      </h1>
      <h1 className="guides-other-small-list__subtitle">
        {t('guides.recommends.description')}
      </h1>

      <div className="guides-other-small-list__list-loading">
        {Array(2)
          .fill('')
          .map((_, i) => (
            <UiLoadingSkeleton
              // eslint-disable-next-line react/no-array-index-key
              key={`guides-other-small-list-loading__${i}`}
              style={{
                width:        '100%',
                height:       '162px',
                borderRadius: '8px',
              }}
            />
          ))}
      </div>
    </section>
  );
};

export const GuidesOtherSmallList = ({
  guides,
  onGuideClick,
}: {
  guides: Array<GuideType>;
  onGuideClick: (guide: GuideType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <section className="guides-other-small-list">
      <div className="guides-other-small-list__title-container">
        <h1 className="guides-other-small-list__title">
          {t('guides-v2.recommended-guides')}
        </h1>
        <Link
          to="/other-guides"
          className="guides-other-small-list__other-guides-link"
        >
          <MoreButton>
            {t('guides-v2.see-all')}
          </MoreButton>
        </Link>
      </div>
      <div className="guides-other-small-list__list">
        {guides.slice(0, 5).map(guide => (
          <button
            key={guide.id}
            type="button"
            className="guides-other-small-list__item"
            onClick={() => onGuideClick(guide)}
          >
            <img
              className="guides-other-small-list__item-image"
              width="64"
              height="64"
              src={guide.image}
              role="presentation"
              alt=""
            />
            <strong className="guides-other-small-list__item-title">
              {guide.name}
            </strong>
            <div className="guides-list-arrow__item-duration mt-1">
              <p>
                {t('guides-v2.lessons')}
                {' '}
                {guide.lessons_count}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                fill="none"
              >
                <circle
                  cx="2.5"
                  cy="2.5"
                  r="2"
                  fill="#565B66"
                />
              </svg>
              <p>
                {guide.units_count}
                {' '}
                {t('guides-v2.levels')}
              </p>
            </div>
          </button>
        ))}
      </div>
    </section>
  );
};
