import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { GuideType } from '../../../../guides/entities/guide';
import { UiPrimaryButton } from '../../../../shared/ui';
import { GuidesV2Analytics } from '../../../shared/analytics';

import './assets/next-guide.css';
import { isEmpty } from 'lodash-es';

export const NextGuide = ({
  selectedGuide,
  guides,
}: {
  selectedGuide: GuideType;
  guides: GuideType[] | undefined;
}) => {
  const { t } = useTranslation();
  const navigation = useHistory();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const localizedTitle = arrow
    ? `guides-v2.${arrow}-arrow`
    : 'guides-v2.ai-arrow';

  const nextGuide = useMemo(() => {
    if (!guides || isEmpty(guides)) return null;

    const selectedGuideIndex = guides.findIndex(
      guide => guide.id === selectedGuide.id,
    );
    const isLastIndex = selectedGuideIndex === guides.length - 1;

    // If we're at the last guide, loop back to first guide, otherwise go to next guide if it exists
    return isLastIndex ? guides[0] : (guides[selectedGuideIndex + 1] || guides[0]);
  }, [guides, selectedGuide]);

  function onNextGuideClick() {
    GuidesV2Analytics.onJumpAheadClick({
      guide_id: nextGuide?.id || '',
    });
    navigation.push(`/guide-pathway/${nextGuide?.id}`);
  }

  if (!nextGuide) {
    return <div className="next-guide-skeleton" />;
  }

  return (
    <>
      <div className="next-guide-separator">{t('guides-v2.next-lesson')}</div>
      <div className="next-guide">
        <div className="next-guide__tags">
          {t(localizedTitle).toUpperCase()}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="5"
            fill="none"
          >
            <circle
              cx="2.5"
              cy="2.5"
              r="2"
              fill="#5653FE"
            />
          </svg>
          {nextGuide?.name}
        </div>
        <h2 className="next-guide__heading">
          {' '}
          {nextGuide?.name}
        </h2>
        <p className="next-guide__description">{nextGuide?.description}</p>
        <UiPrimaryButton onClick={() => onNextGuideClick()}>
          {t('guides-v2.jump-next')}
        </UiPrimaryButton>
      </div>
    </>
  );
};
