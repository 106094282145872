import { currencySign as currencySignMapping } from '@/types/Currency';

import { useMemo } from 'react';

import { useCurrencySignQuery } from '@/hooks/use-currency-sign-query';
import { useUpsellSingleQuery } from '@/modules/upsell/upsell-single/model';

export const useCurrencySign = (paywallConfigId: string) => {
  const currencySignQuery = useCurrencySignQuery();
  const upsellOfferSingleQuery = useUpsellSingleQuery({ paywallConfigId });

  return useMemo(() => {
    if (!upsellOfferSingleQuery.data || !currencySignQuery.data) {
      return '$'; // Default fallback currency
    }

    const { currency } = upsellOfferSingleQuery.data;

    return currency === 'USD'
      ? currencySignQuery.data
      : currencySignMapping[currency];
  }, [currencySignQuery.data, upsellOfferSingleQuery.data]);
};
