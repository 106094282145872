import { useTranslation } from 'react-i18next';

import { UiPrimaryButton } from '@/modules/shared/ui';

interface PathDetailsHeaderProps {
  onResumeClick: () => void;
  arrow: string;
}

export const PathDetailsHeader = ({
  onResumeClick,
  arrow,
}: PathDetailsHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="px-4 py-[18px]">
      <h3 className="mb-2 text-2xl font-semibold">
        {t(`guides-v2.path-details.${arrow}.title`)}
      </h3>
      <p className="mb-6 text-sm text-secondary">
        {t(`guides-v2.path-details.${arrow}.description`)}
      </p>
      <UiPrimaryButton onClick={onResumeClick}>
        {t('guides-v2.resume-path')}
      </UiPrimaryButton>
    </div>
  );
};
