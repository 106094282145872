import { ReviewCard } from '@/entities/review-card';
import { GigaUpsell } from '@/modules/upsell/upsell-single/entities/giga-upsell-content/types';

interface UpsellReviewType {
  data: GigaUpsell['pages']['reviews'];
}

export const UpsellReviews = ({ data }: UpsellReviewType) => (
  <div className="upsell__reviews upsell-giga__reviews">
    <h3 className="upsell__reviews-title">{data.title}</h3>

    <div className="upsell__reviews-list">
      {data?.reviewList
        && data.reviewList.map((item, i) => (
          <ReviewCard
            // eslint-disable-next-line react/no-array-index-key
            key={`upsell-review__${i}`}
            name={item.reviewerName}
            text={item.reviewText}
            className="upsell-giga__review-card"
          />
        ))}
    </div>
  </div>
);
