import { type FC } from 'react';

import classNames from 'classnames';

import { useTimer } from '@/shared/hooks/useTimer';

interface IProps {
  timerName: string;
  title: string;
  className?: string;
}

export const TimerBlock: FC<IProps> = ({ title, timerName = '', className }) => {
  const { timerDuration } = useTimer(timerName, { m: '10', s: '00' });

  return (
    <div className={classNames(className, 'flex flex-col items-center"')}>
      <p className="text-[10px] leading-3">{title}</p>
      <p className="my-[2px] font-semibold text-2xl leading-8">
        <span className="inline-flex flex-col items-center w-[40px]">
          {timerDuration.m}
          <span className="text-sm font-normal text-[10px] leading-3">
            min
          </span>
        </span>
        <span className="inline-flex justify-center">
          :
        </span>
        <span className="inline-flex flex-col items-center w-[40px]">
          {timerDuration.s}
          <span className="text-sm font-normal text-[10px] leading-3">
            sec
          </span>
        </span>
      </p>
    </div>
  );
};
