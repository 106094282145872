import { Trans, useTranslation } from 'react-i18next';

import { useABTestUpsellTimer } from '@/entities/experiments/lib/hooks/useABTestUpsellTimer';
import { GigaUpsell } from '@/modules/upsell/upsell-single/entities/giga-upsell-content/types';

interface IUpsellBillingDescriptionProps {
  hasVat: boolean;
  discountedPrice: string;
  data: GigaUpsell['pages']['pricing'];
}

export const UpsellBillingDescription = ({ hasVat, discountedPrice, data }: IUpsellBillingDescriptionProps) => {
  const { isFeatureEnabled: isUpsellTimerEnabled } = useABTestUpsellTimer();
  const { t } = useTranslation();

  const getBillingDescription = () => {
    if (isUpsellTimerEnabled) {
      return t('compliant-upsell.pricing.billing-description');
    }

    return hasVat ? data?.billing?.descriptionVat : data?.billing?.description;
  };

  const billingDescription = getBillingDescription();

  return (
    <div className="upsell__billing-description">
      <Trans
        i18nKey={
          billingDescription
        }
        values={{ discountPrice: discountedPrice }}
        components={{ b: <b /> }}
      />
    </div>
  );
};
