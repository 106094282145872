/* eslint-disable react/no-array-index-key */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable max-len */
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  useHistory,
  useParams,
} from 'react-router-dom';

import Bugsnag from '@bugsnag/js';
import {
  useFeatureIsOn,
  useFeatureValue,
  useGrowthBook,
} from '@growthbook/growthbook-react';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { userDataQuery } from '@/context/atoms/User';
import { ApplePayButton, useApplePay } from '@/entities/apple-pay';
import { useABTestMegaUpsell } from '@/entities/experiments/lib/hooks/useABTestMegaUpsell';
import { useABTestUpsellTimer } from '@/entities/experiments/lib/hooks/useABTestUpsellTimer';
import { usePrefetchOnboardingContent } from '@/entities/onboarding-content/model';
import { PAYMENT_METHODS } from '@/entities/payment-method';
import { usePersonalProfileQuery } from '@/entities/personal-profile';
import { useCurrencySign } from '@/entities/upsell/lib/useCurrencySign';
import { useFormattedPrices } from '@/entities/upsell/lib/useFormattedPrice';
import { ChaseBottomsheet } from '@/entities/upsell/ui/ChaseBottomSheet';
import { useLocationQuery } from '@/hooks/use-location-query';
import i18n from '@/i18n';
import { Loader } from '@/modules/shared';
import CustomSpinner from '@/modules/shared/CustomSpinner/CustomSpinner';
import { UiPrimaryButton } from '@/modules/shared/ui';
import { UpsellAnalytics } from '@/modules/upsell/shared/analytics';
import { useWebToAppAndroidExperiment } from '@/modules/upsell/shared/hooks/use-web-to-app-android-experiment';
import { useWebToAppExperiment } from '@/modules/upsell/shared/hooks/use-web-to-app-experiment';
import { UiHeader } from '@/modules/upsell/shared/ui';
import { useGigaUpsellContent } from '@/modules/upsell/upsell-single/entities/giga-upsell-content/model';
import { GigaUpsell } from '@/modules/upsell/upsell-single/entities/giga-upsell-content/types';
import { analyticsService } from '@/services/analytics.service';
import {
  getUpsellImageUrl,
  interpolateString,
  isAndroid,
  isIOS16OrLater,
} from '@/shared/lib';
import {
  getExpiredDate,
  isExpired,
  setExpiredDate,
  setTimerInterval,
} from '@/shared/lib/timer';

import { useBuyUpsellOfferMutation, useUpsellSingleQuery } from '../model';

import { UpsellBenefitsBlock } from './UpsellBenefitsBlock';
import { UpsellBillingDescription } from './UpsellBillingDescription';
import { UpsellReviews } from './UpsellReviews';

import './assets/upsell-giga.css';
import './assets/upsell.css';

function useRefCallback() {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const setRefCallback = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setRef(node);
    }
  }, []);

  return {
    ref,
    setRefCallback,
  };
}

export const UpsellSingleGigaPage = () => {
  const navigation = useHistory();
  const isWebToAppScreenShow = useFeatureIsOn('ios_web2app_screen_show');

  const params = useParams<{
    paywallConfigId: string;
    from: string;
  }>();
  const { paywallConfigId } = params;

  const locationQuery = useLocationQuery();
  const fromQuery = locationQuery.get('from');
  const assistantQuery = locationQuery.get('assistant');

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userData = useRecoilValue(userDataQuery);
  const { prefetchOnboardingContent } = usePrefetchOnboardingContent(userPersonalProfile.arrow || 'ai');

  const growthBook = useGrowthBook();

  const growthBookUpsellGigaValue = useFeatureValue(
    'exp_upsell_megaupsell',
    'default',
  );

  const { t } = useTranslation(undefined, {
    keyPrefix: 'upsell-single-giga.giga_upsell',
  });

  const { t: tGlobal } = useTranslation();

  const webToAppExperiment = useWebToAppExperiment();
  const androidWebToAppExperiment = useWebToAppAndroidExperiment();

  const { data: upsellData } = useGigaUpsellContent(
    paywallConfigId || 'default',
    i18n.language,
  );

  const [isUpsellBottomSheetVisible, setIsUpsellBottomSheetVisible] =    useState(false);

  const [isDiscountUpsell, setIsDiscountUpsell] = useState(false);

  const TIMER_NAME = isDiscountUpsell ? 'mega-chase-upsell-timer' : 'mega-upsell-timer';
  const [timerName, setTimerName] = useState(TIMER_NAME);

  const { ref: topPricingRef, setRefCallback: setTopPricingRef } =    useRefCallback();

  const { ref: bottomPricingRef, setRefCallback: setBottomPricingRef } =    useRefCallback();

  const [isTopBenefitsVisible, setIsTopBenefitsVisible] = useState(false);

  const [isBottomBenefitsVisible, setIsBottomBenefitsVisible] = useState(false);

  const [isAboveThanTopBenefitsBlock, setIsAboveThanTopBenefitsBlock] =    useState(false);

  const {
    lastPaymentMethodQuery,
    isApplePayAvailableInSystem,
    isLastPaymentMethodApplePay,
    isApplePay,
    isOneClick,
  } = useApplePay();

  const timerInterval = useRef<NodeJS.Timeout | null>(null);

  const upsellOfferSingleQuery = useUpsellSingleQuery({
    paywallConfigId: upsellData?.paywallConfigId || 'giga_upsell',
  });

  const { isFeatureEnabled: isMegaUpsellEnabled } = useABTestMegaUpsell();
  const { isFeatureEnabled: isUpsellTimerEnabled, bucket: upsellTimerBucket } = useABTestUpsellTimer();

  const currencySign = useCurrencySign(upsellData?.paywallConfigId || 'giga_upsell');

  const initialPricing = useMemo(() => {
    if (upsellOfferSingleQuery.data) {
      return upsellOfferSingleQuery.data.initialPrice;
    }

    return null;
  }, [upsellOfferSingleQuery.data]);

  const isTimerExpiredAndTestEnabled = useMemo(() => isExpired(TIMER_NAME) && isUpsellTimerEnabled, [TIMER_NAME, isUpsellTimerEnabled]);

  const pricing = useMemo(() => {
    if (upsellOfferSingleQuery.data) {
    // If timer expired, use full price
      if (isTimerExpiredAndTestEnabled) {
        return upsellOfferSingleQuery.data.fullPrice;
      }

      return isDiscountUpsell
        ? upsellOfferSingleQuery.data.discountedPrice
        : upsellOfferSingleQuery.data.initialPrice;
    }

    return null;
  }, [upsellOfferSingleQuery.data, isDiscountUpsell, TIMER_NAME]);

  const isUpsellAvailable = useMemo(
    () => (isLastPaymentMethodApplePay ? isApplePayAvailableInSystem : true),
    [isLastPaymentMethodApplePay],
  );
  const upsellOfferSingleMutation = useBuyUpsellOfferMutation();

  const {
    fullPrice,
    absoluteDiscountPrice,
    discountedPrice,
    vatPrice,
    currencyCode,
  } = useFormattedPrices(pricing, currencySign);

  const hasVat = useMemo(() => Boolean(pricing && pricing.vat > 0), [pricing]);

  const isOfferPaywallConfig = paywallConfigId.includes('_single');

  function goToNextPage(isPurchased = false) {
    window.scroll(0, 0);

    const basePath = isMegaUpsellEnabled ? '/upsell-mega/upsell_bundle_new_design_test' : '/upsell-cascade';

    const queryParams = new URLSearchParams();

    if (isPurchased) {
      queryParams.append('isReportOfferAdded', 'true');
    }

    if (fromQuery) {
      queryParams.append('arrow', fromQuery);
    }

    if (assistantQuery) {
      queryParams.append('assistant', assistantQuery);
    }

    const path = generatePath(basePath, {
      assistant: assistantQuery || undefined,
    });
    const fullPath = `${path}${
      queryParams.toString() ? `?${queryParams.toString()}` : ''
    }`;

    return navigation.push(fullPath);
  }

  function onSkip() {
    UpsellAnalytics.onSkip({
      position:          'top',
      place:             'signup_gigaupsell',
      paywall_config_id: isOfferPaywallConfig
        ? 'offer_single'
        : 'upsells_notion_report',
      paymentMethod:         lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      is_oneclick:           isOneClick,
      upsellName:            paywallConfigId,
      complianceTest:        upsellTimerBucket,
    });

    if (isDiscountUpsell) {
      return goToNextPage();
    }

    return setIsUpsellBottomSheetVisible(true);
  }

  function onShowDiscountedUpsell() {
    setIsUpsellBottomSheetVisible(false);
    setIsDiscountUpsell(true);

    setTimerName('mega-chase-upsell-timer');

    window.scroll(0, 0);
  }

  function intersectionObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.target === topPricingRef) {
        setIsAboveThanTopBenefitsBlock(entry.boundingClientRect.top > 0);
        setIsTopBenefitsVisible(entry.isIntersecting);
      }

      if (entry.target === bottomPricingRef) {
        setIsBottomBenefitsVisible(entry.isIntersecting);
      }
    });
  }

  useEffect(() => {
    if (topPricingRef && bottomPricingRef) {
      const intersectionObserver = new IntersectionObserver(
        intersectionObserverCallback,
        {
          root:       null,
          rootMargin: '0px',
          threshold:  1.0,
        },
      );

      intersectionObserver.observe(topPricingRef);
      intersectionObserver.observe(bottomPricingRef);

      return () => {
        intersectionObserver.disconnect();
      };
    }

    return undefined;
  }, [topPricingRef, bottomPricingRef]);

  function onPurchaseNewUpsell() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place:             'signup_gigaupsell',
        pricingId:         pricing.id,
        offerId:           upsellOfferSingleQuery.data.id,
        price:             pricing.discount_price,
        currency:          currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod:         lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick:           isOneClick,
        upsellName:            paywallConfigId,
        complianceTest:        upsellTimerBucket,
      });

      upsellOfferSingleMutation
        .mutateAsync({
          offerId:         upsellOfferSingleQuery.data.id,
          paywallConfigId: upsellData?.paywallConfigId || 'giga_upsell',
          pricingId:       pricing.id,
        })
        .catch((cause) => {
          throw new Error('ERROR_PURCHASE_NEW_UPSELL', { cause });
        })
        .finally(() => {
          setTimeout(() => {
            goToNextPage(true);
          }, 500);
        });
    }
  }

  function onPurchaseClick() {
    if ((isTopBenefitsVisible || isBottomBenefitsVisible) && !isApplePay) {
      return onPurchaseNewUpsell();
    }

    const scrollToTopBlock = () => window.scrollTo({
      top:      isDiscountUpsell ? 850 : 650, // @description - topPricingRef top - 200
      behavior: 'smooth',
    });

    const scrollToBottomBlock = () => {
      if (bottomPricingRef) {
        return bottomPricingRef.scrollIntoView({
          behavior: 'smooth',
        });
      }

      return undefined;
    };

    if (isAboveThanTopBenefitsBlock && !isApplePay) {
      return scrollToTopBlock();
    }

    if (!isAboveThanTopBenefitsBlock && !isApplePay) {
      return scrollToBottomBlock();
    }

    if (isApplePay) {
      return scrollToTopBlock();
    }

    return undefined;
  }

  function onApplePaySuccess() {
    setTimeout(() => {
      goToNextPage(true);
    }, 500);
  }

  function onApplePayTryToPay() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place:             'signup_gigaupsell',
        pricingId:         pricing.id,
        offerId:           upsellOfferSingleQuery.data.id,
        price:             pricing.discount_price,
        currency:          currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod:         PAYMENT_METHODS.APPLE_PAY,
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick:           isOneClick,
        upsellName:            paywallConfigId,
        complianceTest:        upsellTimerBucket,
      });
    }
  }

  function onApplePayLoaderClick() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onApplePayLoaderClick({
        place:             'signup_gigaupsell',
        pricingId:         pricing.id,
        offerId:           upsellOfferSingleQuery.data.id,
        price:             pricing.discount_price,
        currency:          currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        is_oneclick:    isOneClick,
        upsellName:     paywallConfigId,
        complianceTest: upsellTimerBucket,
      });
    }
  }

  const SELECTION_OPTIONS =    upsellData?.pages.selectionOptions.selectionOptionsList;

  const STEPS = upsellData?.pages.selectionOptions.selectionOptionsList;

  const BUY_BUTTON = isUpsellTimerEnabled ? tGlobal('compliant-upsell.buy-button') : t('purchase-button');

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const compliantUpsellReviews = tGlobal('compliant-upsell.reviews', {
    returnObjects: true,
  }) as GigaUpsell['pages']['reviews'];

  const REVIEWS = isUpsellTimerEnabled ? compliantUpsellReviews : upsellData?.pages.reviews ?? { title: '', reviewList: [] };

  useEffect(() => {
    if (
      upsellOfferSingleQuery.data
      && pricing
      && !lastPaymentMethodQuery.isLoading
      && isUpsellAvailable
    ) {
      UpsellAnalytics.pageView({
        place:             'signup_gigaupsell',
        offerId:           upsellOfferSingleQuery.data.id,
        pricingId:         pricing.id,
        price:             pricing.discount_price,
        currency:          currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod:                      lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell:              growthBookUpsellGigaValue,
        exp_upsell_upsell_cascade_redesign: false,
        is_oneclick:                        isOneClick,
        upsellName:                         paywallConfigId,
        complianceTest:                     upsellTimerBucket,
      });

      UpsellAnalytics.legacyPageView({
        price:          pricing.discount_price,
        currency:       currencyCode,
        isDiscountUpsell,
        isOneClick,
        upsellName:     paywallConfigId,
        complianceTest: upsellTimerBucket,
      });
    }
  }, [
    upsellOfferSingleQuery.data,
    pricing,
    lastPaymentMethodQuery.isLoading,
    isUpsellAvailable,
  ]);

  useEffect(() => {
    prefetchOnboardingContent().catch(() => Bugsnag.notify(new Error('NO_ONBOARDING_CONTENT')));
  }, [userPersonalProfile.data?.personalization_criteria.arrow, i18n.language]);

  useEffect(() => {
    if (!lastPaymentMethodQuery.isLoading && !isUpsellAvailable) {
      UpsellAnalytics.onAllUpsellSkip({
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick:           isOneClick,
        complianceTest:        upsellTimerBucket,
      });

      goToNextPage();
    }
  }, [isUpsellAvailable, lastPaymentMethodQuery.isLoading]);

  useEffect(() => {
    if (isIOS16OrLater() && isWebToAppScreenShow) {
      analyticsService.setAmplitudeUserProperties({
        exp_web2a_v2: webToAppExperiment,
      });
    }
  }, [webToAppExperiment]);

  useEffect(() => {
    if (isAndroid()) {
      analyticsService.setAmplitudeUserProperties({
        exp_web2a_android_v1: androidWebToAppExperiment,
      });
    }
  }, [androidWebToAppExperiment]);

  useEffect(() => {
    upsellOfferSingleQuery.refetch().catch(() => {});
  }, [paywallConfigId]);

  const onSetTimerInterval = (timerName: string) => {
    timerInterval.current = setTimerInterval(
      timerName,
      () => {},
    );
  };

  const destroyTimer = () => {
    if (!timerInterval.current) return;

    clearInterval(timerInterval.current);
    timerInterval.current = null;
  };

  const initTimer = useCallback((name: string = timerName) => {
    if (isExpired(name)) {
      return;
    }

    const timer = getExpiredDate(name);

    if (!timer) {
      const expiredDate = dayjs().add(10, 'm').toDate();

      setExpiredDate(name, expiredDate);
    }

    onSetTimerInterval(name);
  }, [timerName]);

  useEffect(() => {
    if (!userPersonalProfile.data || !userData) {
      return;
    }

    growthBook
      ?.setAttributes({
        ...growthBook?.getAttributes(),
        registered_at: dayjs(userData?.registered_at).format(
          'YYYY-MM-DDTHH:mm',
        ),
        arrow: userPersonalProfile.arrow,
      })
      .catch(() => {});
  }, [userPersonalProfile.data, userData]);

  useEffect(() => () => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }
  }, []);

  useEffect(() => {
    initTimer(timerName);

    return () => {
      destroyTimer();
    };
  }, [initTimer, timerName]);

  if (lastPaymentMethodQuery.isLoading || !isUpsellAvailable || !upsellData) {
    return <Loader />;
  }

  return (
    <>
      {upsellOfferSingleMutation.isLoading && <CustomSpinner />}

      <main className="upsell vh-full">
        <UiHeader
          events={{
            onSkip: () => onSkip(),
          }}
          timerName={isUpsellTimerEnabled ? TIMER_NAME : undefined}
        />

        <div className="upsell__content upsell-giga__content">
          {isDiscountUpsell && pricing && initialPricing && !isTimerExpiredAndTestEnabled && (
            <div className="upsell-giga__discount">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="56"
                fill="none"
              >
                <rect
                  width="56"
                  height="56"
                  fill="#5653FE"
                  rx="28"
                />
                <path
                  fill="#EEF"
                  fillRule="evenodd"
                  d="M17.938 16.125A5.687 5.687 0 0 1 28 12.49a5.687 5.687 0 0 1 8.858 7.135H38.5A4.376 4.376 0 0 1 42.875 24v2.187a1.313 1.313 0 0 1-1.313 1.313H29.838a.525.525 0 0 1-.526-.525V20.92A5.716 5.716 0 0 1 28 19.76a5.715 5.715 0 0 1-1.313 1.158v6.057a.525.525 0 0 1-.524.525H14.437a1.313 1.313 0 0 1-1.312-1.313V24a4.376 4.376 0 0 1 4.375-4.375h1.642a5.663 5.663 0 0 1-1.204-3.5Zm8.75 0a3.063 3.063 0 1 0-6.126 0 3.063 3.063 0 0 0 6.125 0Zm2.625 0a3.063 3.063 0 1 0 6.125 0 3.063 3.063 0 0 0-6.126 0Z"
                  clipRule="evenodd"
                />
                <path
                  fill="#EEF"
                  d="M26.688 30.387a.525.525 0 0 0-.525-.524h-9.277a1.459 1.459 0 0 0-1.435 1.21 20.283 20.283 0 0 0 0 6.853l.392 2.291a3.513 3.513 0 0 0 3.071 2.898l1.864.209c1.785.2 3.578.325 5.374.376a.52.52 0 0 0 .535-.524V30.387Zm3.16 13.313a.52.52 0 0 1-.535-.524V30.387a.525.525 0 0 1 .524-.524h9.277c.71 0 1.316.51 1.435 1.21.39 2.269.39 4.585 0 6.853l-.39 2.291a3.514 3.514 0 0 1-3.073 2.898l-1.864.209c-1.785.2-3.578.325-5.374.376Z"
                />
              </svg>

              <div className="upsell-giga__discount-content">
                <h6 className="upsell-giga__discount-subtitle">
                  <Trans
                    i18nKey={interpolateString(
                      upsellData.pages.pricing?.discount?.subtitle,
                      initialPricing.discount.toString(),
                    )}
                    components={{
                      b: <b />,
                    }}
                  />
                </h6>
                <h5 className="upsell-giga__discount-title">
                  {interpolateString(
                    upsellData.pages.pricing?.discount?.title,
                    pricing.discount.toString(),
                  )}
                </h5>
              </div>
            </div>
          )}
          <h1
            className="upsell__title upsell-giga__title"
            dangerouslySetInnerHTML={{
              __html: upsellData.title,
            }}
          />
          <h2
            className="upsell-giga__subtitle"
            dangerouslySetInnerHTML={{ __html: upsellData.subtitle }}
          />
          <UpsellBenefitsBlock data={upsellData.pages} />
          <div ref={setTopPricingRef}>
            <h2 className="upsell__title upsell-giga__title">
              {upsellData.pages.pricing?.pricingInformation?.title}
            </h2>

            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {upsellData.pages.pricing?.pricingInformation?.firstTitle}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-amount">
                  {discountedPrice}
                </div>
                {!isTimerExpiredAndTestEnabled && (
                  <div className="upsell-giga__price-amount upsell-giga__price-strike">
                    {fullPrice}
                  </div>
                )}
              </div>
            </div>

            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {upsellData.pages.pricing?.pricingInformation?.secondTitle}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-free">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    fill="none"
                  >
                    <path
                      fill="#F25151"
                      d="M5.167 1.151A.293.293 0 0 1 5.221.91.282.282 0 0 1 5.44.8c.044.001.088.012.129.032.342.137 2.694 1.266 2.15 4.532.79-.485.507-1.426.766-1.426.097 0 .136.037.196.123.864 1.256 1.518 2.754 1.518 4.156a4.327 4.327 0 0 1-1.23 3.028A4.159 4.159 0 0 1 6 12.5a4.159 4.159 0 0 1-2.97-1.255A4.327 4.327 0 0 1 1.8 8.217c0-3.477 4.022-4.118 3.367-7.066Z"
                    />
                    <path
                      fill="#FFA1A1"
                      d="M5.893 5.429a.315.315 0 0 0-.174-.054.3.3 0 0 0-.199.073.237.237 0 0 0-.082.177c.28 1.5-1.688 2-1.688 3.749 0 .53.237 1.04.659 1.415A2.4 2.4 0 0 0 6 11.375a2.4 2.4 0 0 0 1.591-.586c.422-.376.659-.885.659-1.415 0-1.425-1.412-3.326-2.357-3.945Z"
                    />
                  </svg>
                  <span>{t('pricing.free')}</span>
                </div>
                <div className="upsell-giga__price-amount upsell-giga__price-strike">
                  {currencySign}
                  19.99
                </div>
              </div>
            </div>
            {hasVat && (
              <div className="upsell-giga__price">
                <div className="upsell-giga__price-label">{t('total')}</div>
                <div className="upsell-giga__price-content">
                  <div className="upsell-giga__price-amount-gray">
                    {vatPrice}
                  </div>
                </div>
              </div>
            )}

            <UpsellBillingDescription
              hasVat={hasVat}
              discountedPrice={absoluteDiscountPrice}
              data={upsellData.pages.pricing}
            />

            {upsellOfferSingleQuery.data && pricing && (
              <div className="upsell_purchase-button upsell-giga_purchase-button px-0">
                {isApplePay ? (
                  <ApplePayButton
                    offerId={upsellOfferSingleQuery.data.id}
                    pricingId={pricing.id}
                    paywallConfigId={paywallConfigId}
                    events={{
                      onTryToPay:    onApplePayTryToPay,
                      onLoaderClick: onApplePayLoaderClick,
                      onSuccess:     onApplePaySuccess,
                    }}
                  />
                ) : (
                  <UiPrimaryButton
                    className="upsell__add-button"
                    onClick={() => onPurchaseNewUpsell()}
                  >
                    {BUY_BUTTON}
                  </UiPrimaryButton>
                )}
              </div>
            )}
          </div>
          <h3 className="upsell__title upsell-giga__title upsell-giga__selections-title">
            {upsellData.pages.selectionOptions.title}
          </h3>
          <div className="upsell-giga__selections-options">
            {SELECTION_OPTIONS
              && SELECTION_OPTIONS.map((option, i) => (
                <div
                  key={`upsell-giga-option-${i}`}
                  className="upsell-giga__option"
                >
                  <div className="upsell-giga__option-content">
                    <h5 className="upsell-giga__option-title">
                      {option.title}
                    </h5>
                    <p className="upsell-giga__option-description">
                      {option.description}
                    </p>
                  </div>

                  <div className="upsell-giga__option-image">
                    <img
                      width="312"
                      height="256"
                      src={getUpsellImageUrl(option.image)}
                      role="presentation"
                      alt=""
                    />
                  </div>
                </div>
              ))}
          </div>
          <h3 className="upsell__title upsell-giga__title">
            {upsellData.pages.steps.title}
          </h3>
          <div className="upsell-giga__steps">
            {STEPS
              && STEPS.map((step, i) => (
                <div
                  key={`upsell-giga-step-${i}`}
                  className="upsell-giga__step"
                >
                  <h6 className="upsell-giga__step-counter">
                    {t('steps.counter', { count: i + 1 })}
                  </h6>
                  <h4 className="upsell-giga__step-title">{step.title}</h4>
                  <p className="upsell-giga__step-description">
                    {step.description}
                  </p>
                </div>
              ))}
          </div>
          <UpsellReviews data={REVIEWS} />
          <h3 className="upsell__title upsell-giga__title">
            {upsellData.pages.bottomContent.title}
          </h3>
          <h4 className="upsell-giga__subtitle">
            {upsellData.pages.bottomContent.subtitle}
          </h4>
          <UpsellBenefitsBlock data={upsellData.pages} />
          <div ref={setBottomPricingRef}>
            <h2 className="upsell__title upsell-giga__title">
              {upsellData.pages.pricing?.pricingInformation?.title}
            </h2>

            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {upsellData.pages.pricing?.pricingInformation?.firstTitle}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-amount">
                  {discountedPrice}
                </div>
                {!isTimerExpiredAndTestEnabled && (
                  <div className="upsell-giga__price-amount upsell-giga__price-strike">
                    {fullPrice}
                  </div>
                )}
              </div>
            </div>

            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {upsellData.pages.pricing?.pricingInformation?.secondTitle}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-free">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    fill="none"
                  >
                    <path
                      fill="#F25151"
                      d="M5.167 1.151A.293.293 0 0 1 5.221.91.282.282 0 0 1 5.44.8c.044.001.088.012.129.032.342.137 2.694 1.266 2.15 4.532.79-.485.507-1.426.766-1.426.097 0 .136.037.196.123.864 1.256 1.518 2.754 1.518 4.156a4.327 4.327 0 0 1-1.23 3.028A4.159 4.159 0 0 1 6 12.5a4.159 4.159 0 0 1-2.97-1.255A4.327 4.327 0 0 1 1.8 8.217c0-3.477 4.022-4.118 3.367-7.066Z"
                    />
                    <path
                      fill="#FFA1A1"
                      d="M5.893 5.429a.315.315 0 0 0-.174-.054.3.3 0 0 0-.199.073.237.237 0 0 0-.082.177c.28 1.5-1.688 2-1.688 3.749 0 .53.237 1.04.659 1.415A2.4 2.4 0 0 0 6 11.375a2.4 2.4 0 0 0 1.591-.586c.422-.376.659-.885.659-1.415 0-1.425-1.412-3.326-2.357-3.945Z"
                    />
                  </svg>
                  <span>{t('pricing.free')}</span>
                </div>

                <div className="upsell-giga__price-amount upsell-giga__price-strike">
                  {currencySign}
                  19.99
                </div>
              </div>
            </div>

            {hasVat && (
              <div className="upsell-giga__price">
                <div className="upsell-giga__price-label">{t('total')}</div>
                <div className="upsell-giga__price-content">
                  <div className="upsell-giga__price-amount-gray">
                    {vatPrice}
                  </div>
                </div>
              </div>
            )}

            <UpsellBillingDescription
              hasVat={hasVat}
              discountedPrice={absoluteDiscountPrice}
              data={upsellData.pages.pricing}
            />
          </div>
          {!isTopBenefitsVisible && upsellOfferSingleQuery.data && pricing && (
            <div className="upsell_purchase-button upsell-giga_purchase-bottom-button px-0">
              <UiPrimaryButton
                className="upsell__add-button"
                onClick={() => onPurchaseClick()}
              >
                {BUY_BUTTON}
              </UiPrimaryButton>
            </div>
          )}
        </div>
      </main>

      <ChaseBottomsheet
        isVisible={isUpsellBottomSheetVisible}
        events={{
          onClose: onShowDiscountedUpsell,
        }}
        data={{
          ...upsellData.pages.chase.chaseGroup,
          image: getUpsellImageUrl(upsellData.pages.chase.chaseGroup.image),
        }}
      />
    </>
  );
};
