import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { isEmpty, trim } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { UiLoadingSkeleton, UiRetryButton } from '../../../shared/ui';
import { UiSpinner } from '../../../shared/ui/spinner';
import { scrollToBottom } from '../../../shared/utils';
import './chat-form.css';

export const ChatForm = ({
  newMessage,
  isPendingAnswer,
  isMessagesLoading,
  onSubmit,
  onFormClick,
  onRegeneratePrompt,
  setMessage,
  message,
}: {
  newMessage: { text: string; errorMessage: string } | null;
  isPendingAnswer: boolean;
  isMessagesLoading?: boolean;
  onSubmit: (text: string) => void;
  onFormClick?: () => void;
  onRegeneratePrompt: () => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  message: string;
}) => {
  const { t } = useTranslation();
  const textField = useRef<HTMLTextAreaElement>(null);
  const [localContent, setLocalContent] = useState(message);

  const isFormFieldActive = !isEmpty(trim(message));
  const isMessageError = Boolean(newMessage?.errorMessage);

  function clearTextField() {
    if (textField.current) {
      textField.current.value = '';
      setLocalContent('');
      setMessage('');
    }
  }

  function handleDeleteKey(e: KeyboardEvent<HTMLTextAreaElement>) {
    const target = e.target as HTMLTextAreaElement;

    if (trim(target.value) === '') {
      clearTextField();
    }
  }

  function onTextFieldInput(e: ChangeEvent<HTMLTextAreaElement>) {
    const newText = e.target.value;

    setLocalContent(newText);
    setMessage(newText);

    if (textField.current) {
      textField.current.style.height = 'auto';
      textField.current.style.height = `${textField.current.scrollHeight}px`;
    }
  }

  function focusToTextField() {
    onFormClick?.();
    textField.current?.focus?.();
  }

  function blurTextField() {
    textField.current?.blur?.();
  }

  function submitMessage() {
    if (!isFormFieldActive) return;

    const trimmedMessage = trim(message);

    if (!trimmedMessage) return;

    clearTextField();
    blurTextField();

    if (textField.current) {
      textField.current.style.height = 'auto';
    }

    onSubmit(trimmedMessage);
    scrollToBottom();
    setMessage('');
  }

  function onKeyUp(e: KeyboardEvent<HTMLTextAreaElement>) {
    switch (e.code) {
      case 'Backspace':
        handleDeleteKey(e);
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    if (textField.current && textField.current.value !== message) {
      textField.current.value = message;
      setLocalContent(message);
    }
  }, [message]);

  if (isMessagesLoading) {
    return (
      <div className="chat-form">
        <UiLoadingSkeleton
          style={{
            height:       '49px',
            width:        '100%',
            borderRadius: '1rem',
          }}
        />
      </div>
    );
  }

  if (isMessageError) {
    return (
      <div className="chat-form">
        <UiRetryButton
          text={t('ai-bots.chat.regenerate-button')}
          onRetry={() => onRegeneratePrompt()}
        />
      </div>
    );
  }

  return (
    <div className="chat-form">
      <div
        className="chat-form__input-container"
        onClick={focusToTextField}
      >
        <textarea
          ref={textField}
          disabled={isPendingAnswer}
          className="chat-form__input"
          placeholder={
            (isPendingAnswer
              ? t('ai-bots.chat.loading')
              : t('ai-bots.chat.input-placeholder')) || ''
          }
          onKeyUp={e => onKeyUp(e)}
          onChange={e => onTextFieldInput(e)}
          rows={1}
          value={localContent}
        />

        {isPendingAnswer && <UiSpinner className="chat-form__spinner" />}

        <button
          type="button"
          disabled={!isFormFieldActive}
          className="chat-form__send-button"
          style={{ opacity: isFormFieldActive ? '1' : '0' }}
          onClick={submitMessage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="currentColor"
              d="M4.827 4.06a.571.571 0 0 0-.816.623l1.604 5.543a.572.572 0 0 0 .453.405l6.503 1.089c.307.06.307.5 0 .56l-6.503 1.09a.572.572 0 0 0-.453.404L4.01 19.317a.571.571 0 0 0 .816.623l14.858-7.43a.572.572 0 0 0 0-1.02L4.827 4.06Z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
