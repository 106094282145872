import { GuideLessonDetailsCalloutType } from '@/modules/guides/entities/guide-lesson/types';

interface GuideLessonCalloutType {
  chunk: GuideLessonDetailsCalloutType;
}

export const GuideLessonCallout = ({
  chunk,
}: GuideLessonCalloutType) => (
  <div>
    {chunk.content.map(item => (
      <div className="bg-fill-secondary rounded-lg mt-4 mx-4 px-3 py-4 flex flex-col gap-y-[2px]">
        <h4 className="guide-lesson-quiz__title">{item.title}</h4>
        <p dangerouslySetInnerHTML={{ __html: item.text }} />
      </div>
    ))}
  </div>
);
