import { Trans, useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { ImageSkeleton } from '@/modules/guides/features/image-skeleton';
import { UiPrimaryButton } from '@/modules/shared/ui';

import BannerImage from '../assets/png/banner.png';
import CloseIcon from '../assets/svg/close.svg';

export const AiToolsUpdatesBottomSheet = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'whats-new.certificates',
  });

  return (
    <BottomSheet
      key="new-upsell-chase-bottomsheet"
      open={isVisible}
    >
      <div className="upsell-chase-bottomsheet max-w-[500px] mx-auto">
        <div className="flex w-full justify-between">
          <h1 className="text-[18px] font-bold">
            {t('heading')}
          </h1>
          <button
            type="button"
            className="explore-new-bots__close-button"
            onClick={() => onClose()}
          >
            <img
              src={CloseIcon}
              alt=""
            />
          </button>
        </div>
        <ImageSkeleton
          className="upsell-chase-bottomsheet__image mt-4 w-full"
          height="218px"
          src={BannerImage}
          alt=""
        />
        <div className="upsell-chase-bottomsheet__container">
          <h1
            className="upsell-chase-bottomsheet__title mt-4 text-[16px]"
          >
            {t('title')}
          </h1>
          <p
            className="text-sm mt-1"
          >
            <Trans
              i18nKey={t('description')}
              components={{ b: <b /> }}
            />
          </p>
          <div className="upsell-chase-bottomsheet__actions">
            <UiPrimaryButton onClick={onClose}>
              {t('button-text')}
            </UiPrimaryButton>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};
