import i18next from 'i18next';

import { analyticsService } from '@/services/analytics.service';

type ReportPlace = 'guides_lesson' | 'guides_task';

interface ReportBaseProps {
  guideId: string;
  lessonId: string;
  chunkId: string;
}

interface ReportSubmitProps extends ReportBaseProps {
  selectedIssues: string[];
  textFeedback?: string;
}

function onReportButtonClick({ guideId, lessonId, chunkId }: ReportBaseProps) {
  analyticsService.sendEvent('webapp_guides_click', {
    place:     'learning_lesson_page',
    guide_id:  guideId,
    lesson_id: lessonId,
    chunk_id:  chunkId,
    action:    'report',
    language:  i18next.language,
  });
}

function onReportModalView({
  guideId,
  lessonId,
  chunkId,
  place,
}: ReportBaseProps & { place: ReportPlace }) {
  analyticsService.sendEvent('webapp_report_issue_view', {
    place,
    guide_id:  guideId,
    lesson_id: lessonId,
    chunk_id:  chunkId,
    language:  i18next.language,
  });
}

function onReportModalSubmit({
  guideId,
  lessonId,
  chunkId,
  place,
  selectedIssues,
  textFeedback,
}: ReportSubmitProps & { place: ReportPlace }) {
  analyticsService.sendEvent('webapp_report_issue_click', {
    action:    'submit',
    place,
    guide_id:  guideId,
    lesson_id: lessonId,
    chunk_id:  chunkId,
    issues:    selectedIssues.join(', '),
    ...(textFeedback && { text_feedback: textFeedback }),
    language:  i18next.language,
  });
}

function onReportModalClose({
  guideId,
  lessonId,
  place,
}: Omit<ReportBaseProps, 'chunkId'> & { place: ReportPlace }) {
  analyticsService.sendEvent('webapp_report_issue_click', {
    action:    'close',
    place,
    guide_id:  guideId,
    lesson_id: lessonId,
    language:  i18next.language,
  });
}

export {
  onReportButtonClick,
  onReportModalView,
  onReportModalSubmit,
  onReportModalClose,
  type ReportPlace,
  type ReportBaseProps,
  type ReportSubmitProps,
};
