import dayjs from 'dayjs';

const UPSELL_MEDIA_URL = process.env.REACT_APP_UPSELL_MEDIA_URL;

export function iOSVersion() {
  const match = navigator?.userAgent?.match(/OS (\d+)_?(\d+)?/) ?? null;

  return match !== null ? parseInt(match[1], 10) : 0;
}

export function isIOS16OrLater() {
  return iOSVersion() >= 16;
}

export function androidVersion() {
  const match = navigator?.userAgent?.match(/Android (\d+)\.?(\d+)?/) ?? null;

  return match !== null ? parseInt(match[1], 10) : 0;
}

export function isAndroid() {
  return androidVersion() >= 8;
}

export function getPlatform(
  fallback: 'ios' | 'android',
): 'ios' | 'android' | 'default' {
  if (isIOS16OrLater()) {
    return 'ios';
  }

  if (isAndroid()) {
    return 'android';
  }

  return fallback;
}

export function isAfterDate(register_date?: string, expStartDate?: string) {
  if (!register_date || !expStartDate) {
    return false;
  }

  const registrationDate = dayjs(register_date);
  const experimentStartDate = dayjs(expStartDate);

  return (
    registrationDate.isAfter(experimentStartDate)
    || registrationDate.isSame(experimentStartDate)
  );
}

export function getUpsellImageUrl(image: string) {
  return `${UPSELL_MEDIA_URL}/${image}`;
}

export function interpolateString(str: string, word: string) {
  return str.replace(/\{\{(\w+)\}\}/g, (_, key) => word || '');
}

function getBrowserVersion(): { name: string; version: string } {
  const ua = navigator.userAgent;
  let name = 'unknown';
  let version = '0';

  if (ua.includes('Chrome')) {
    name = 'chrome';
    version = ua.match(/Chrome\/(\d+)/)?.[1] ?? '0';
  } else if (ua.includes('Firefox')) {
    name = 'firefox';
    version = ua.match(/Firefox\/(\d+)/)?.[1] ?? '0';
  } else if (ua.includes('Safari')) {
    name = 'safari';
    version = ua.match(/Version\/(\d+)/)?.[1] ?? '0';
  } else if (ua.includes('Edge')) {
    name = 'edge';
    version = ua.match(/Edge\/(\d+)/)?.[1] ?? '0';
  }

  return { name, version };
}

export function getOsWithVersion(): string {
  const iosVer = iOSVersion();
  const androidVer = androidVersion();

  if (iosVer > 0) {
    return `ios_${iosVer}`;
  }

  if (androidVer > 0) {
    return `android_${androidVer}`;
  }

  if (typeof window !== 'undefined' && window.navigator) {
    const browser = getBrowserVersion();

    return `web_${browser.name}_${browser.version}`;
  }

  return 'unknown_0';
}
