import dayjs from 'dayjs';
import Cookies from 'js-cookie';

export const setExpiredDate = (name: string, endDate: Date, expiresDay = 10) => {
  Cookies.set(name, endDate.toISOString(), { expires: expiresDay });

  return endDate.toISOString();
};

export const getExpiredDate = (name: string) => {
  const date = Cookies.get(name);

  return date ? dayjs(date).toDate() : null;
};

export const isExpired = (name: string) => {
  const date = getExpiredDate(name);

  return date ? date.getTime() < Date.now() : false;
};

export const setTimerInterval = (
  name: string,
  endCallback: () => void,
  callback?: (expiredTime?: Date| null) => void,
) => {
  const timer = setInterval(() => {
    if (isExpired(name)) {
      clearInterval(timer);

      if (typeof endCallback === 'function') {
        endCallback();
      }
    }

    if (typeof callback === 'function') {
      callback(getExpiredDate(name));
    }
  }, 1000);

  return timer;
};

export const getRemainingTime = (expiredDate: Date): {m: string; s: string} => {
  const now = dayjs();
  const diffInMillis = dayjs(expiredDate).diff(now);

  if (diffInMillis <= 0) {
    // Если время истекло
    return {
      m: '00',
      s: '00',
    };
  }

  const minutes = Math.floor(diffInMillis / 60000); // Получаем минуты
  const seconds = Math.floor((diffInMillis % 60000) / 1000); // Получаем секунды

  return {
    m: String(minutes).padStart(2, '0'),
    s: String(seconds).padStart(2, '0'),
  };
};
