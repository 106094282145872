import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ReviewCard } from '@/entities/review-card';

interface IMegaUpsellContentProps {
  discountedPercentOffText: string;
  discountedPrice: string;
  isDiscountUpsell: boolean;
}

export const MegaUpsellContent: React.FC<IMegaUpsellContentProps> = ({
  discountedPercentOffText,
  discountedPrice,
  isDiscountUpsell,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const featuresList = t('mega-upsell.banner.features', {
    returnObjects: true,
  }) as string[];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const reviewsList = t('mega-upsell.reviews.reviews', {
    returnObjects: true,
  }) as { reviewerName: string; reviewText: string }[];

  return (
    <div className="upsell__content">
      <h1 className="upsell__title">{t('mega-upsell.banner.title')}</h1>
      <div className="upsell__features">
        {isDiscountUpsell && (
          <div className="upsell__discount-flag">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="78"
              fill="none"
            >
              <path
                fill="#5653FE"
                d="M0 0h22.5v78H0l17.5-39L0 0Z"
              />
            </svg>
            {discountedPercentOffText.split(' ').join('\n')}
          </div>
        )}
        <ul className="upsell__features-list">
          {featuresList.map((item, i) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`upsell_feature_${i}`}
              className="upsell__feature"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <rect
                  width="20"
                  height="20"
                  fill="#5653FE"
                  rx="10"
                />
                <path
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14.666 6.5 8.25 12.917 5.333 10"
                />
              </svg>
              <p>{item}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="upsell__reviews">
        <h3 className="upsell__reviews-title">{t('mega-upsell.reviews.title')}</h3>
        <div className="upsell__reviews-list">
          {reviewsList.map((item, i) => (
            <ReviewCard
              // eslint-disable-next-line react/no-array-index-key
              key={`upsell-review__${i}`}
              name={item.reviewerName}
              text={item.reviewText}
            />
          ))}
        </div>
      </div>
      <div className="upsell__billing-description">
        <Trans
          i18nKey={t('mega-upsell.pricing.disclaimer')}
          values={{ discountPrice: discountedPrice }}
          components={{ b: <b /> }}
        />
      </div>
    </div>
  );
};
